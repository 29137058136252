import React from "react";
import { AddAgents } from "../../../../features/knowledge-base/views/admin/components/onboarding/invite-agents";
import { styled } from "@hiyllo/ux/styled";

const Container = styled("div", (props) => ({
  margin: "20px 20px",
  color: props.$theme.foreground,
}));

export const AdminAddAgentsView = React.memo(
  function AdminAddAgentsView(): JSX.Element {
    return (
      <Container>
        <AddAgents />
      </Container>
    );
  }
);
