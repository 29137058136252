import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import { useHeaderContent } from "../../../../../../providers/header-content-provider";
import * as ChangeOnboardinStatusBP from "../../../../../../blueprints/onboarding/set-onboarding-status";
import { useAlert } from "../../../../../../providers/alert-provider";
import { client } from "../../../../../../singletons/moopsy-client";

const PageWrapper = styled("div", () => ({
  minHeight: "80vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MotionContainer = styled(motion.div, (props) => ({
  maxWidth: "800px",
  width: "100%",
  margin: "0 auto",
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingTop: "20px",
  textAlign: "center",
  color: props.$theme.foreground,
}));

const CompletionIcon = styled("div", (props) => ({
  fontSize: "80px",
  color: props.$theme.colorSubtleAccent,
  marginBottom: "20px",
}));

const ExitButton = styled("button", (props) => ({
  padding: "12px 24px",
  borderRadius: "30px",
  border: "none",
  backgroundColor: props.$theme.colorSubtleAccent,
  color: props.$theme.background1,
  fontSize: "18px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: props.$theme.colorSubtleAccent,
  },
}));

const ButtonContainer = styled("div", () => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "40px",
}));

const FinishViewComponent = () => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const { setHeaderContent } = useHeaderContent();

  const changeOnboardinStatusMutation =
    client.useMutation<ChangeOnboardinStatusBP.Plug>(ChangeOnboardinStatusBP);

  const handleFinishOnboarding = React.useCallback(() => {
    try {
      changeOnboardinStatusMutation.call({});
      window.location.reload();
    } catch {
      pushAlert("Failed to update onboarding status", "error");
    }
  }, [changeOnboardinStatusMutation, pushAlert]);

  React.useEffect(() => {
    setHeaderContent(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          color: theme.foreground,
        }}
      >
        <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>
          Congratulations!
        </h1>
      </div>
    );

    return () => setHeaderContent(null);
  }, [setHeaderContent, theme.foreground]);

  return (
    <PageWrapper>
      <MotionContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <CompletionIcon>
          <FontAwesomeIcon icon={faCheckCircle} />
        </CompletionIcon>
        <h1
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: theme.foreground,
          }}
        >
          You're All Set!
        </h1>
        <p
          style={{
            fontSize: "18px",
            marginBottom: "40px",
            color: theme.foreground,
          }}
        >
          You've completed the setup for your knowledge base. You're ready to
          start using it.
        </p>
        <ButtonContainer>
          <ExitButton onClick={handleFinishOnboarding}>
            Exit to Dashboard <FontAwesomeIcon icon={faArrowRight} />
          </ExitButton>
        </ButtonContainer>
      </MotionContainer>
    </PageWrapper>
  );
};

export const FinishView = React.memo(FinishViewComponent);
