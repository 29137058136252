/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  name: string;
  logo: string;
  primaryColor: string;
  secondaryColor: string;
};
export type ResponseType = {};
export const Endpoint = "onboarding/setup-appearance";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = false;

export const RateLimitingConfig = {
  calls: 5,
  per: 1500,
};
export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"logo":{"type":"string"},"primaryColor":{"type":"string"},"secondaryColor":{"type":"string"}},"required":["logo","name","primaryColor","secondaryColor"],"$schema":"http://json-schema.org/draft-07/schema#"}