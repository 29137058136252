import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import React from "react";
import { useHeaderContent } from "../../../../../../providers/header-content-provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { AddAgents } from "../../components/onboarding/invite-agents";
import { client } from "../../../../../../singletons/moopsy-client";
import * as FetchAgentsInvitesBP from "../../../../../../blueprints/onboarding/fetch-agents-invites";
import { AgentInviteType } from "../../../../../../types/agent-invites";
import { useNavigate } from "react-router-dom";

const Container = styled("div", {
  margin: "20px 20px",
  marginTop: "24px",
  overflowY: "auto",
});

const ExplanatoryText = styled("div", (props) => ({
  backgroundColor: props.$theme.background3,
  borderRadius: "8px",
  padding: "20px",
  marginBottom: "15px",
  color: props.$theme.foreground,
  lineHeight: "1.6",
}));

const AddAgentsViewComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setHeaderContent } = useHeaderContent();
  const [invites, setInvites] = React.useState<AgentInviteType[] | null>(null);

  const agentInvitesQuery = client.useQuery<FetchAgentsInvitesBP.Plug>(
    FetchAgentsInvitesBP,
    {}
  );

  React.useEffect(() => {
    const allAgentInvites: AgentInviteType[] =
      agentInvitesQuery.data?.invites ?? [];

    if (allAgentInvites) {
      setInvites(allAgentInvites);
    }
  }, [agentInvitesQuery.data?.invites]);

  React.useEffect(() => {
    setHeaderContent(
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            color: theme.foreground,
          }}
        >
          <h1
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              color: theme.foreground,
            }}
          >
            Invite Agents
          </h1>
        </div>
      </>
    );

    return () => setHeaderContent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeaderContent]);

  return (
    <Container>
      <ExplanatoryText>
        <h2 style={{ marginTop: 0 }}>Lets invite some agents</h2>
        <p>
          Agents are the people who will help you manage your support workspace.
          You can invite them by entering their email address below. Once
          invited, they will receive an email with a link to join your
          workspace.
        </p>
        <p>
          You can invite Agents by clicking on the{" "}
          <FontAwesomeIcon icon={faPlus} size="sm" />{" "}
          <strong>Invite Agent</strong> button, after entering their email
          address.
        </p>
      </ExplanatoryText>
      <AddAgents />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        {invites !== null ? (
          <button
            style={{
              backgroundColor: theme.colorSubtleAccent,
              color: theme.foreground,
              padding: "8px 16px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/admin/dashboard/knowledge-base/finish-onboarding");
            }}
          >
            Finish onboarding
          </button>
        ) : (
          <button
            style={{
              backgroundColor: theme.colorWarning,
              color: theme.foreground,
              padding: "8px 16px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/admin/dashboard/knowledge-base/finish-onboarding");
            }}
          >
            Skip, I'll do this later
          </button>
        )}
      </div>
    </Container>
  );
};

export const AddAgentsView = React.memo(AddAgentsViewComponent);
