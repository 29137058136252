import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { client } from "../../../../../singletons/moopsy-client";
import { Article } from "../../../../../types/knowledge-base/article";
import { Section } from "../../../../../types/knowledge-base/section";
import * as RetrieveKnowledgeBaseItemsBP from "../../../../../blueprints/knowledge-base/section/retrieve-knowledge-base-items";
import * as RetrieveSectionBP from "../../../../../blueprints/knowledge-base/section/retrieve-section";
import { SectionCard } from "../components/section-card";
import { ArticleCard } from "../../admin/components/section/article-card";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import NoContent from "../../../../../components/no-content";
import { useMediaQuery } from "react-responsive";
import { Footer } from "../../../../../shared/footer";
import { IconDisplay } from "../../../../../features/knowledge-base/components/icon-display";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

export const SectionContent: React.FC = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const dataQuery = client.useQuery<RetrieveKnowledgeBaseItemsBP.Plug>(
    RetrieveKnowledgeBaseItemsBP,
    { parent: uuid ?? null }
  );
  const sectionQuery = client.useQuery<RetrieveSectionBP.Plug>(
    RetrieveSectionBP,
    { uuid: uuid ?? "" }
  );

  const onSectionClick = (section: Section) => {
    navigate(`/section/${section.uuid}`);
  };

  const onArticleClick = (article: Article) => {
    navigate(`/article/${article.uuid}`);
  };

  if (dataQuery.isLoading || sectionQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (dataQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationCircle}
        label="Error"
        hint={dataQuery.error?.message}
      />
    );
  }

  if (sectionQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationCircle}
        label="Error"
        hint={sectionQuery.error?.message}
      />
    );
  }

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            marginBottom: 20,
            gap: 5,
          }}
        >
          <IconDisplay
            iconName={sectionQuery.data.section?.icon as string}
            size={40}
          />
          <h1>{sectionQuery.data.section?.title}</h1>
          <p style={{ opacity: 0.6 }}>
            {sectionQuery.data.section?.description}
          </p>
        </div>
      </div>
      <div
        style={{
          marginTop: 75,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 20,
          zIndex: 1,
        }}
      >
        {dataQuery.data.articles.map((article) => (
          <ArticleCard
            key={article.uuid}
            article={article}
            onClick={() => onArticleClick(article)}
            endUser={true}
          />
        ))}

        {dataQuery.data.sections.map((subSection) => (
          <SectionCard
            key={subSection.uuid}
            icon={subSection.icon as string}
            title={subSection.title}
            description={subSection.description}
            onClick={() => onSectionClick(subSection)}
          />
        ))}
        {dataQuery.data.articles.length === 0 &&
          dataQuery.data.sections.length === 0 && (
            <NoContent plug="No content found" />
          )}
      </div>
    </div>
  );
};
