/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Conversation } from "../../../types/conversations/conversation";

export type ParamsType = {};
export type ResponseType = {
  conversations: Conversation[];
};
export const Endpoint = "admin/conversations/get-all-conversations";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"object","properties":{},"$schema":"http://json-schema.org/draft-07/schema#"}