import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useTheme } from "@hiyllo/ux/theme";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { IconDisplay } from "../../../../../features/knowledge-base/components/icon-display";
import { motion } from "framer-motion";

interface SectionCardProps {
  icon: string;
  title: string;
  description: string;
  onClick: () => void;
}

const Container = styled<"div", React.ComponentProps<typeof motion.div>>(
  motion.div,
  ({ $theme }) => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "250px",
    maxWidth: "100%",
    minWidth: "250px",
    height: "175px",
    maxHeight: "175px",
    backgroundColor:
      $theme.colorScheme === "light"
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.3)",
    border: `1px solid ${$theme.midground1}`,
    borderRadius: "10px",
    color: $theme.foreground,
    padding: "20px",
    boxSizing: "border-box",
    cursor: "pointer",
    transition: "all 0.3s ease",
    zIndex: 1,
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  })
);

export const SectionCard: React.FC<SectionCardProps> = React.memo(
  function SectionCard({ icon, title, description, onClick }): JSX.Element {
    const theme = useTheme();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      onClick();
    };

    return (
      <Container
        onClick={handleClick}
        initial={{ opacity: 0, x: 5, y: 5 }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={{ opacity: 0, x: 5, y: 5 }}
        transition={{ duration: 0.1 }}
      >
        <div style={{ paddingBottom: 20 }}>
          <IconDisplay
            iconName={icon}
            size="2x"
            color={theme.foreground}
            iconStyle={{ opacity: 0.6 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <h2 style={{ fontSize: "1rem", margin: 0 }}>{title}</h2>
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            style={{ color: theme.foreground, opacity: 0.6 }}
          />
        </div>
        <p
          style={{
            fontSize: "0.875rem",
            margin: 0,
            color: theme.foreground,
            opacity: 0.6,
          }}
        >
          {description}
        </p>
      </Container>
    );
  }
);
