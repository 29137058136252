/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  parentUuid: string;
  uuid: string;
  subSections: string[];
};
export type ResponseType = {
  success: boolean;
};
export const Endpoint = "section/update-section-order";
export const Method = "POST";
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 10,
  per: 5000,
};
export const paramsSchema = {"type":"object","properties":{"parentUuid":{"type":"string"},"uuid":{"type":"string"},"subSections":{"type":"array","items":{"type":"string"}}},"required":["parentUuid","subSections","uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}