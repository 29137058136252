import { authExtension } from "../../singletons/moopsy-client";
import { DefaultRolesEnum } from "../../types/users/roles";

export function useIsInternal(): boolean {
  const authState = authExtension.useAuthState();

  return (
    (authState?.roles.includes(DefaultRolesEnum.agent) ||
      authState?.roles.includes(DefaultRolesEnum.admin)) ??
    false
  );
}
