import React from "react";

import * as CompleteSamlSSOLoginBP from "../blueprints/auth/complete-login";
import { LoadingPage } from "@hiyllo/ux/standard-loader";
import { authExtension, client, login } from "../singletons/moopsy-client";
import { useNavigate } from "react-router-dom";
import { AuthExtensionStatus } from "@moopsyjs/react/main";

export const SSO = React.memo(function SSO(): JSX.Element {
  const authStatus = authExtension.useAuthStatus();
  const completeSamlSSOLoginMutation =
    client.useMutation<CompleteSamlSSOLoginBP.Plug>(CompleteSamlSSOLoginBP);
  const [error, setError] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const loginPendingRef = React.useRef(false);
  const authStatusRef = React.useRef(authStatus);
  authStatusRef.current = authStatus;

  React.useEffect(() => {
    void (async () => {
      if (loginPendingRef.current) return;
      if (authStatusRef.current !== AuthExtensionStatus.loggedOut) return;

      loginPendingRef.current = true;
      console.log("[SSO] Performing SSO Login");

      const usp = new window.URLSearchParams(window.location.search);

      try {
        const { tokenPackage } = await completeSamlSSOLoginMutation.call({
          token: usp.get("token") ?? "",
        });

        console.log("[SSO] Received a token package");

        await login(tokenPackage.plainToken);

        console.log("[SSO] Logged in");

        navigate("/");
      } catch (e: any) {
        setError(e.description ?? e.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return <LoadingPage />;
});
