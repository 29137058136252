import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import {
  Article,
  DocumentContentsV2,
} from "../../../../../types/knowledge-base/article";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import NoContent from "../../../../../components/no-content";
import { motion } from "framer-motion";
import * as CreateArticleBP from "../../../../../blueprints/knowledge-base/article/create-article";
import * as UpdateArticleBP from "../../../../../blueprints/knowledge-base/article/update-article";
import * as UpdateArticlesBP from "../../../../../blueprints/knowledge-base/article/update-articles";
import * as DeleteArticlesBP from "../../../../../blueprints/knowledge-base/article/delete-articles";
import * as RetrieveAllArticlesBP from "../../../../../blueprints/knowledge-base/article/retrieve-all-articles";
import { authExtension } from "../../../../../singletons/moopsy-client";
import { client } from "../../../../../singletons/moopsy-client";
import { SectionLookup } from "../../../../../features/knowledge-base/components/section-lookup";
import { Section } from "../../../../../types/knowledge-base/section";
import { useParams, useNavigate } from "react-router-dom";
import { EditorComponent } from "../components/article/article-editor";
import { TableComponent } from "../components/article/table";
import { useAlert } from "../../../../../providers/alert-provider";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useShowConfirm, useShowDialog } from "@hiyllo/ux/dialogs";
import { MultiSelectActionBar } from "../../../../../features/knowledge-base/components/multi-select-action-bar";

const Container = styled("div", {
  margin: "20px 20px",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const SearchBar = styled("div", (props: { $theme: ThemeType }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.$theme.background3,
  borderRadius: "20px",
  padding: "8px 16px",
  width: "300px",
  color: props.$theme.foreground,
}));

const Button = styled("button", {
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
});

const Options = styled("div", {
  display: "flex",
  gap: "8px",
});

export const ArticleView: React.FC = React.memo(() => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid: string }>();
  const { pushAlert } = useAlert();
  const showDialog = useShowDialog();
  const showConfirm = useShowConfirm();
  const authState = authExtension.useAuthState();
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [selectedArticles, setSelectedArticles] = React.useState<Article[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [editedTitle, setEditedTitle] = React.useState<string>("");
  const [initialTitle, setInitialTitle] = React.useState<string>("");
  const [editingArticle, setEditingArticle] = React.useState<Article | null>(
    null
  );
  const [isSectionLookupOpen, setIsSectionLookupOpen] =
    React.useState<boolean>(false);
  const [editedArticleContent, setEditedArticleContent] =
    React.useState<DocumentContentsV2 | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingEditingArticle, setIsLoadingEditingArticle] =
    React.useState<boolean>(true);

  const getAllArticlesQuery = client.useQuery<RetrieveAllArticlesBP.Plug>(
    RetrieveAllArticlesBP,
    {}
  );

  const createArticleMutation = client.useMutation<CreateArticleBP.Plug>(
    CreateArticleBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  const updateArticleMutation = client.useMutation<UpdateArticleBP.Plug>(
    UpdateArticleBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  const updateArticlesMutation = client.useMutation<UpdateArticlesBP.Plug>(
    UpdateArticlesBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  const deleteSectionMutation = client.useMutation<DeleteArticlesBP.Plug>(
    DeleteArticlesBP,
    { querySideEffects: [getAllArticlesQuery] }
  );

  React.useEffect(() => {
    if (editingArticle) {
      setEditedTitle(editingArticle.title);
      setInitialTitle(editingArticle.title);
    }
  }, [editingArticle]);

  React.useEffect(() => {
    const fetchArticles = async () => {
      setIsLoading(true); // Start loading
      try {
        const allArticles = getAllArticlesQuery.data?.articles ?? [];
        setArticles(allArticles);
      } catch (error) {
        console.error("Failed to load articles", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of success or error
      }
    };
    fetchArticles();
  }, [getAllArticlesQuery.data?.articles]);

  React.useEffect(() => {
    // Wait for articles data to be fully loaded before setting `editingArticle`
    if (!isLoading && articles.length > 0 && uuid) {
      const article = articles.find((article) => article.uuid === uuid);

      if (article) {
        setEditingArticle(article);
      }
    }
  }, [uuid, articles, isLoading]);

  React.useEffect(() => {
    if (editingArticle) {
      console.log("Editing Article", editingArticle);

      const draftContent = localStorage.getItem(`draft-${editingArticle.uuid}`);
      const parsedDraftContent = draftContent ? JSON.parse(draftContent) : null;

      // If there's a draft, check if it's identical to the article content
      const isDraftIdentical =
        parsedDraftContent &&
        JSON.stringify(parsedDraftContent) ===
          JSON.stringify(editingArticle.content);

      if (isDraftIdentical) {
        // Delete the draft if it's identical to the current article content
        localStorage.removeItem(`draft-${editingArticle.uuid}`);
        setEditedArticleContent(editingArticle.content);
        setIsLoadingEditingArticle(false);
      } else if (
        parsedDraftContent &&
        parsedDraftContent.descendants?.length > 0 &&
        JSON.stringify(parsedDraftContent) !==
          JSON.stringify(editedArticleContent)
      ) {
        // If the draft is different, prompt the user to load it
        showConfirm({
          title: "Load Draft",
          message:
            "A draft version of this article was found. Do you want to load it?",
          confirmLabel: "Yes",
        }).then((confirmed) => {
          if (confirmed) {
            setEditedArticleContent(parsedDraftContent);
          } else {
            setEditedArticleContent(editingArticle.content);
          }
          setIsLoadingEditingArticle(false);
        });
      } else {
        // No draft found or no differences, load the original content
        setEditedArticleContent(editingArticle.content);
        setIsLoadingEditingArticle(false);
      }
    }
  }, [editingArticle]);

  const filteredArticles = React.useMemo(
    () =>
      articles.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [articles, searchTerm]
  );

  const handleTitleChange = React.useCallback(() => {
    const uuids = selectedArticles.map((article) => article.uuid);

    showDialog({
      title: "Rename Article",
      message: `Enter the new title for the selected articles`,
      requireValue: true,
      defaultValue:
        selectedArticles.length === 1 ? selectedArticles[0].title : "",
      onSubmit: async (newTitle) => {
        if (!newTitle) {
          pushAlert("Title cannot be empty", "error");
          return;
        }
        try {
          await updateArticlesMutation.call({
            uuids,
            title: newTitle,
          });

          setArticles((prevArticles) =>
            prevArticles.map((article) =>
              uuids.includes(article.uuid)
                ? { ...article, title: newTitle }
                : article
            )
          );

          setSelectedArticles([]);
        } catch (error) {
          pushAlert("An error occurred while renaming the articles.", "error");
        }
      },
    });
  }, [pushAlert, selectedArticles, showDialog, updateArticlesMutation]);

  const handlePublishToggle = React.useCallback(async () => {
    const uuids = selectedArticles.map((article) => article.uuid);
    const allPublished = selectedArticles.every((article) => article.published);
    const action = allPublished ? "Un-Publish" : "Publish";

    showDialog({
      title: `${action} Articles`,
      message: `Are you sure you want to ${action} ${selectedArticles.length} articles?`,
      onConfirm: async () => {
        try {
          await updateArticlesMutation.call({
            uuids, // Pass the array of UUIDs
            published: !allPublished, // Toggle publish status
          });

          // Update local state to reflect the change
          setArticles((prevArticles) =>
            prevArticles.map((article) =>
              uuids.includes(article.uuid)
                ? { ...article, published: !allPublished }
                : article
            )
          );
          setSelectedArticles([]);
        } catch (error) {
          pushAlert(
            "An error occurred while updating the publish status.",
            "error"
          );
        }
      },
    });
  }, [pushAlert, selectedArticles, showDialog, updateArticlesMutation]);

  const handleSectionSelect = async (section: Section) => {
    const uuids = selectedArticles.map((article) => article.uuid);

    try {
      // Update the articles with the new parent section UUID in batch
      await updateArticlesMutation.call({
        uuids, // Pass the array of UUIDs
        parentSectionUUID: section.uuid,
      });

      // Update the local state to reflect the changes
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          uuids.includes(article.uuid)
            ? { ...article, parentSectionUUID: section.uuid }
            : article
        )
      );

      // Clear the selection and close the section lookup modal
      setSelectedArticles([]);
      setIsSectionLookupOpen(false);
    } catch (error) {
      pushAlert("An error occurred while assigning the section.", "error");
    }
  };

  const handleDeleteArticle = React.useCallback(async () => {
    const uuids = selectedArticles.map((article) => article.uuid);
    // Prompt the user to confirm
    showConfirm({
      title: "Delete Article",
      message: `Are you sure you want to delete ${selectedArticles.length} articles?`,
    }).then(async (confirmed) => {
      if (confirmed) {
        // Call the delete mutation with the array of UUIDs
        await deleteSectionMutation.call({
          uuids, // Pass the array of UUIDs
        });

        // Update local state to remove the deleted articles
        setArticles((prevArticles) =>
          prevArticles.filter((article) => !uuids.includes(article.uuid))
        );
        setSelectedArticles([]);
      }
    });
  }, [deleteSectionMutation, selectedArticles, showConfirm]);

  const handleAssignSection = React.useCallback(() => {
    console.log("Assign Section");
    setIsSectionLookupOpen(true);
  }, []);

  const handleSaveContent = React.useCallback(async () => {
    if (!editingArticle) return;

    // Retrieve content from local storage
    const draftContent = localStorage.getItem(`draft-${editingArticle.uuid}`);
    if (draftContent) {
      setIsSaving(true);
      try {
        await updateArticleMutation.call({
          uuid: editingArticle.uuid,
          content: JSON.parse(draftContent),
        });

        localStorage.removeItem(`draft-${editingArticle.uuid}`);
      } catch (error) {
        pushAlert("An error occurred while saving the article.", "error");
      } finally {
        setIsSaving(false);
      }
    } else {
      alert("No changes to save");
    }
  }, [editingArticle, pushAlert, updateArticleMutation]);

  const handleAddArticle = React.useCallback((): void => {
    createArticleMutation.call({
      title: "New Article",
      parentSectionUUID: "",
      authorUUID: authState?.uuid || "",
    });
  }, [authState?.uuid, createArticleMutation]);

  const handleCancelEdit = React.useCallback((): void => {
    setEditingArticle(null);
  }, []);

  return (
    <Container>
      {editingArticle ? (
        <>
          {editingArticle && editedArticleContent && (
            <EditorComponent
              article={editingArticle}
              content={editedArticleContent}
              onContentChange={setEditedArticleContent}
              updateArticleMutation={updateArticleMutation}
              onSave={handleSaveContent}
              onCancel={handleCancelEdit}
              isSaving={isSaving}
              isLoading={isLoadingEditingArticle}
            />
          )}
        </>
      ) : (
        <>
          {selectedArticles.length > 0 && (
            <>
              {console.log("selectedArticles", selectedArticles)}
              {isSectionLookupOpen && (
                <SectionLookup
                  isModalOpen={isSectionLookupOpen}
                  onClose={() => setIsSectionLookupOpen(false)}
                  onSelectSection={handleSectionSelect}
                />
              )}
              <MultiSelectActionBar
                articles={selectedArticles}
                selectedCount={selectedArticles.length}
                onPublish={handlePublishToggle}
                onDelete={handleDeleteArticle}
                onAssignSection={handleAssignSection}
                onRename={handleTitleChange}
              />
            </>
          )}
          <Header>
            <SearchBar $theme={theme}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="text"
                placeholder="Search articles..."
                style={{
                  border: "none",
                  background: "transparent",
                  marginLeft: "8px",
                  fontSize: "16px",
                  width: "100%",
                  color: theme.foreground,
                  outline: "none",
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchBar>
            <Options>
              <Button
                onClick={handleAddArticle}
                style={{
                  backgroundColor: theme.colorSubtleAccent,
                  color: theme.foreground,
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Article
              </Button>
            </Options>
          </Header>
          <motion.div
            layout="position"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            style={{ marginTop: 20 }}
          >
            {isLoading ? (
              // Show loading spinner if loading
              <LoadingSpinner />
            ) : filteredArticles.length === 0 ? (
              // Show NoContent if no articles are found
              <NoContent
                plug={"No articles found, why don't you create one..."}
              />
            ) : (
              // Show TableComponent with articles if data is loaded and available
              <TableComponent
                data={filteredArticles}
                selectedArticles={selectedArticles}
                setSelectedArticles={setSelectedArticles}
              />
            )}
          </motion.div>
        </>
      )}
    </Container>
  );
});
