import React from "react";
import { authExtension } from "../singletons/moopsy-client";
import { MoopsyClientAuthExtensionState } from "@moopsyjs/react/main";
import { useTheme } from "@hiyllo/ux/theme";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

export const AuthenticationProvider = React.memo(
  function AuthenticationProvider(props: React.PropsWithChildren): JSX.Element {
    const theme = useTheme();
    const authStatus = authExtension.useAuthStatus();

    if (authStatus === MoopsyClientAuthExtensionState.loggingIn) {
      return (
        <div
          style={{
            backgroundColor: theme.background1,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner style={{ width: 100, height: 100 }} />
        </div>
      );
    }

    return <>{props.children}</>;
  }
);
