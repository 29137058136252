import axios from "axios";
import { MoopsyClient, MoopsyClientAuthExtension } from "@moopsyjs/react";
import { AuthSpec } from "../types/auth/auth-spec";

// Create Moopsy client with socket URL based on the environment
export const client = new MoopsyClient({
  socketUrl:
    window.location.hostname === "localhost"
      ? "http://localhost:3001"
      : window.location.origin,
  axios,
  debug: true,
});

const tenant =
  window.location.host === "localhost:3000"
    ? "default"
    : window.location.host.split(".")[0];

// MoopsyClientAuthExtension with auto-login function
export const authExtension = new MoopsyClientAuthExtension<AuthSpec>(client, {
  autoLoginFunction: async (): Promise<AuthSpec["AuthRequestType"] | null> => {
    const plainToken = window.localStorage.getItem("loginToken"); // Retrieve the token from localStorage

    // Check if token exists
    if (plainToken) {
      return {
        plainToken, // Pass it back
        tenant, // Tenant information (if required)
      };
    }

    return null;
  },
});

// Function to handle login and store token
export async function login(token: string) {
  window.localStorage.setItem("loginToken", token); // Store token in localStorage
  await authExtension.login({ plainToken: token, tenant });
}

// Optionally, handle logout and token removal
export async function logout() {
  window.localStorage.removeItem("loginToken"); // Remove the token from localStorage
  await authExtension.logout(); // Call the logout function from the auth extension
  console.log("User logged out");
}
