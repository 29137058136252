import React, { useState } from "react";
import { styled } from "@hiyllo/ux/styled";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faImage,
  faPalette,
  faSignature,
} from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import "../../../../../../index.css";
import { client } from "../../../../../../singletons/moopsy-client";
import * as CreateFileUploadBP from "../../../../../../blueprints/fs/create-file-upload";
import * as SetupAppearanceBP from "../../../../../../blueprints/onboarding/setup-appearance";
import { uploadFileToS3 } from "../../../../../../features/fs";
import { useAlert } from "../../../../../../providers/alert-provider";
import { useTenant } from "../../../../../../providers/tenant-provider";

interface PreviewProps {
  $primaryColor: string;
  $secondaryColor: string;
  $theme: ThemeType;
}

const PageWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: "auto",
});

const Container = styled("div", (props) => ({
  width: "100%",
  maxWidth: "800px",
  color: props.$theme.foreground,
}));

const Preview = styled("div", (props: PreviewProps) => ({
  marginTop: "40px",
  padding: "20px",
  borderRadius: "10px",
  background: `linear-gradient(to right, ${props.$primaryColor}, ${props.$secondaryColor})`,
  color: props.$theme.background1,
  textAlign: "center",
}));

const AppearanceViewComponent = () => {
  const theme = useTheme();
  const tenant = useTenant();
  const { pushAlert } = useAlert();
  const [workspaceName, setWorkspaceName] = useState("");
  const [logo, setLogo] = useState("");
  const [logoFsId, setLogoFsId] = useState("");
  const [primaryColor, setPrimaryColor] = useState("#FF15D9");
  const [secondaryColor, setSecondaryColor] = useState("#FE8E00");

  const createFileUploadMutation =
    client.useMutation<CreateFileUploadBP.Plug>(CreateFileUploadBP);
  const setupAppearanceMutation =
    client.useMutation<SetupAppearanceBP.Plug>(SetupAppearanceBP);

  const handleSave = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!workspaceName) {
      pushAlert("Please enter a name.", "error");
      return;
    }
    const data = {
      name: workspaceName,
      logo: logoFsId,
      primaryColor,
      secondaryColor,
      tenantId: tenant.tenantId,
    };

    console.log(data);

    setupAppearanceMutation.call(data);
    window.location.href = "/admin/dashboard/knowledge-base/learn-articles";
  };

  const handleImageUpload = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const image = e.target.files?.[0];
      if (image) {
        setLogo(URL.createObjectURL(image));
      }
      if (image) {
        const uploadRes = await createFileUploadMutation.call({
          name: "image",
          mimeType: image.type,
          extension: image.type.split("/")[1],
        });

        await uploadFileToS3(
          new File([image], "image"),
          uploadRes.postOpts,
          () => {}
        );

        setLogoFsId(uploadRes.fsId);
      }
    },
    [createFileUploadMutation]
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <PageWrapper>
        <Container>
          <h1
            style={{
              fontSize: "32px",
              fontWeight: "bold",
              marginBottom: "20px",
              color: theme.foreground,
            }}
          >
            Set Up Your Appearance
          </h1>
          <form
            onSubmit={handleSave}
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                htmlFor="workspaceName"
                style={{
                  marginBottom: "8px",
                  fontWeight: "bold",
                  color: theme.foreground,
                }}
              >
                <FontAwesomeIcon icon={faSignature} /> Workspace Name
              </label>
              <input
                id="workspaceName"
                type="text"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                placeholder="Enter your workspace name"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: `1px solid ${theme.midground1}`,
                  backgroundColor: theme.background3,
                  color: theme.foreground,
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                htmlFor="logoUpload"
                style={{
                  marginBottom: "8px",
                  fontWeight: "bold",
                  color: theme.foreground,
                }}
              >
                <FontAwesomeIcon icon={faImage} /> Upload your logo
              </label>
              <input
                id="logoUpload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  display: "none",
                }}
              />
              <button
                type="button"
                onClick={() => document.getElementById("logoUpload")?.click()}
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: `1px solid ${theme.midground1}`,
                  backgroundColor: theme.background3,
                  color: theme.foreground,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  maxHeight: "40px",
                }}
              >
                {logo ? (
                  <>
                    <img
                      src={logo}
                      alt="Logo Preview"
                      style={{ maxWidth: "25px", height: "auto" }}
                    />
                    <span>Click to change logo</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faImage} /> Upload Logo
                  </>
                )}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    htmlFor="primaryColor"
                    style={{
                      marginBottom: "8px",
                      fontWeight: "bold",
                      color: theme.foreground,
                    }}
                  >
                    <FontAwesomeIcon icon={faPalette} /> Primary Color
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      id="primaryColor"
                      type="color"
                      value={primaryColor}
                      onChange={(e) => setPrimaryColor(e.target.value)}
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        outline: "none",
                        border: "none",
                      }}
                    />
                    <input
                      type="text"
                      value={primaryColor}
                      onChange={(e) => setPrimaryColor(e.target.value)}
                      style={{
                        width: "100px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: `1px solid ${theme.midground1}`,
                        backgroundColor: theme.background3,
                        color: theme.foreground,
                      }}
                      placeholder="#FF15D9"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    htmlFor="secondaryColor"
                    style={{
                      marginBottom: "8px",
                      fontWeight: "bold",
                      color: theme.foreground,
                    }}
                  >
                    <FontAwesomeIcon icon={faPalette} /> Secondary Color
                  </label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      id="secondaryColor"
                      type="color"
                      value={secondaryColor}
                      onChange={(e) => setSecondaryColor(e.target.value)}
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        outline: "none",
                        border: "none",
                      }}
                    />
                    <input
                      type="text"
                      value={secondaryColor}
                      onChange={(e) => setSecondaryColor(e.target.value)}
                      style={{
                        width: "100px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: `1px solid ${theme.midground1}`,
                        backgroundColor: theme.background3,
                        color: theme.foreground,
                      }}
                      placeholder="#FE8E00"
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                style={{
                  padding: "12px 24px",
                  borderRadius: "30px",
                  border: "none",
                  backgroundColor: theme.colorSubtleAccent,
                  color: theme.background1,
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "20px",
                  transition: "background-color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  ((e.target as HTMLElement).style.backgroundColor =
                    theme.colorSubtleAccent)
                }
                onMouseOut={(e) =>
                  ((e.target as HTMLElement).style.backgroundColor =
                    theme.colorSubtleAccent)
                }
              >
                <FontAwesomeIcon icon={faSave} /> Save Changes And Continue
              </button>
            </div>
          </form>
          <Preview
            $primaryColor={primaryColor}
            $secondaryColor={secondaryColor}
            $theme={theme}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {logo ? (
                <img
                  src={logo}
                  alt="Logo Preview"
                  style={{ maxWidth: "35px", height: "auto" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faImage}
                  style={{ fontSize: "40px" }}
                  color={theme.foreground}
                />
              )}
              <h2 style={{ margin: 0, color: theme.foreground }}>
                {workspaceName || "Your Workspace Name"}
              </h2>
            </div>
            <p style={{ marginTop: "20px", color: theme.foreground }}>
              This is how your workspace will look.
            </p>
          </Preview>
        </Container>
      </PageWrapper>
    </motion.div>
  );
};

export const AppearanceView = React.memo(AppearanceViewComponent);
