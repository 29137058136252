/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Section } from "../../../types/knowledge-base/section";
import { Article } from "../../../types/knowledge-base/article";

export type ParamsType = {
    searchTerm: string;
};
export type ResponseType = {
    result: (Section | Article)[];
};
export const Endpoint = "util/search";
export const Method = "GET";
export interface Plug {
    params: ParamsType;
    response: ResponseType;
    method: typeof Method;
    endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
    calls: 5,
    per: 1500,
};
export const paramsSchema = {"type":"object","properties":{"searchTerm":{"type":"string"}},"required":["searchTerm"],"$schema":"http://json-schema.org/draft-07/schema#"}