import React from "react";
import { Article } from "../../../../../../types/knowledge-base/article";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@hiyllo/ux/theme";
import { faCircle, faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export const ArticleTableCell: React.FC<{
  article: Article;
  setEditingArticle: React.Dispatch<React.SetStateAction<Article | null>>;
  columnKey: string;
}> = React.memo(({ article, setEditingArticle, columnKey }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getTimeSinceEdit = React.useCallback((date: Date): string => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) return `${diffInDays}d ago`;
    if (diffInHours > 0) return `${diffInHours}h ago`;
    if (diffInMinutes > 0) return `${diffInMinutes}m ago`;
    return "Just now";
  }, []);

  const handleEditArticle = React.useCallback(
    (article: Article) => {
      setEditingArticle(article);
      navigate(`/admin/dashboard/knowledge-base/article/${article.uuid}`);
    },
    [navigate, setEditingArticle]
  );

  switch (columnKey) {
    case "title":
      return (
        <span
          onClick={() => handleEditArticle(article)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            cursor: "pointer",
            color: theme.foreground,
          }}
        >
          <FontAwesomeIcon
            icon={faCircle}
            size={"2xs"}
            style={{ color: article.published ? "#5B9279" : "#FF6978" }}
          />
          <p>{article.title}</p>
          <FontAwesomeIcon
            icon={faPenToSquare}
            size={"sm"}
            style={{ color: theme.colorSubtleAccent }}
          />
        </span>
      );
    case "updatedAt":
      return <span>{getTimeSinceEdit(article.updatedAt)}</span>;
    case "createdAt":
      return <span>{new Date(article.createdAt).toLocaleDateString()}</span>;
    case "published":
      return <span>{article.published ? "Yes" : "No"}</span>;
    case "author":
      return <span>{article.authorName || "Unknown"}</span>;
    default:
      return null;
  }
});
