import { useTheme } from "@hiyllo/ux/theme";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";

interface ColorPickerProps {
  onChange?: (color: string) => void;
  onClose?: () => void;
  defaultColor?: string;
}
export const ColorPicker = React.memo(function ColorPicker(
  props: ColorPickerProps
) {
  const theme = useTheme();
  const localTheme = localStorage.getItem("theme");
  const [color, setColor] = useState<string>(props.defaultColor || "#000000");
  const isLight: boolean = localTheme === "light";

  const Container = styled("div", (props) => ({
    backgroundColor: theme.midground1,
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    padding: 2,
    gap: 2,
  }));

  const PickerTop = styled("div", (props) => ({
    border: "none",
    position: "absolute",
    zIndex: 999,
  }));

  const InnerPickerTop = styled("div", (props) => ({
    backgroundColor: theme.midground1,
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    height: "fit-content",
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  }));

  useEffect(() => {
    if (color) {
      props.onChange?.(color);
    }
  }, [color]);

  return (
    <Container>
      <PickerTop>
        <InnerPickerTop>
          <button
            onClick={() => props.onClose?.()}
            style={{
              border: "none",
              backgroundColor: theme.midground1,
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              style={{
                color: isLight ? "green" : "lightgreen",
                fontSize: "1.5em",
              }}
            />
          </button>
        </InnerPickerTop>
        <SketchPicker
          disableAlpha
          styles={{
            default: {
              picker: {
                background: theme.midground1,
                boxShadow: "none",
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              },
            },
          }}
          onChange={(c) => setColor(c.hex)}
          color={color}
        />
      </PickerTop>
    </Container>
  );
});
