/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { fsId: string };
export type ResponseType = {
  imageSrc: string;
};
export const Endpoint = "fs/get-image-src";
export const Method = "GET";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const isPublic = true;
export const paramsSchema = {"type":"object","properties":{"fsId":{"type":"string"}},"required":["fsId"],"$schema":"http://json-schema.org/draft-07/schema#"}