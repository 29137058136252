export async function retry<T>(fn: () => Promise<T>, maxRetries: number) {
    let retries = 0, error = null;;
    while (retries < maxRetries) {
        try {
            return await fn();
        } catch (err) {
            error = err;
            retries++;
        }
    }

    throw (error ?? new Error("Failed with no error"));
}