import React from "react";
import { faComments, faGears } from "@fortawesome/pro-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { faBook } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";
import { useTenant } from "../../../../providers/tenant-provider";
import { WelcomeView } from "./views/onboarding/welcome-view";
import { AppearanceView } from "./views/onboarding/appearance-view";
import { LearnArticleView } from "./views/onboarding/learn-article-view";
import { LearnSectionView } from "./views/onboarding/learn-section-view";
import { AddAgentsView } from "./views/onboarding/add-agents-view";
import { FinishView } from "./views/onboarding/finish-onboarding";
import * as GetImageSrcBP from "../../../../blueprints/fs/get-image-src";
import { client } from "../../../../singletons/moopsy-client";
import { Dock, Page } from "@hiyllo/ux/tokyo";
import { KnowledgeBaseAdminRouter } from "../../../../features/knowledge-base/admin/router";
import { ConversationsAdminRouter } from "../../../../features/conversations/admin/router";
import { AdminSettingsRouter } from "../../../../features/admin/settings/router";

export const Dashboard = React.memo(function Dashboard(): JSX.Element {
  const navigate = useNavigate();
  const tenant = useTenant();
  const [logoSrc, setLogoSrc] = React.useState<string | null>(null);
  const location = useLocation();

  const getImageSrcMutation =
    client.useMutation<GetImageSrcBP.Plug>(GetImageSrcBP);

  const getLogoSrc = React.useCallback(async () => {
    if (logoSrc) return; // Skip fetch if logoSrc is already loaded

    const logo = await getImageSrcMutation.call({
      fsId: tenant.appearance.logo,
    });

    if (logo.imageSrc) {
      setLogoSrc(logo.imageSrc);
    }
  }, [getImageSrcMutation, tenant.appearance.logo, logoSrc]);

  React.useEffect(() => {
    if (tenant.appearance.logo && !logoSrc) {
      getLogoSrc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page.Container>
      <Dock.Container>
        <Dock.Top>
          <Dock.Button
            isActive={location.pathname.startsWith(
              "/admin/dashboard/knowledge-base"
            )}
            icon={faBook}
            tooltip="Knowledge Base"
            onClick={() => navigate("knowledge-base")}
          />
          <Dock.Button
            isActive={location.pathname.startsWith(
              "/admin/dashboard/conversations"
            )}
            icon={faComments}
            tooltip="Conversations"
            onClick={() => navigate("conversations")}
          />
          <Dock.Button
            isActive={location.pathname.startsWith("/admin/dashboard/settings")}
            icon={faGears}
            tooltip="Settings"
            onClick={() => navigate("settings")}
          />
        </Dock.Top>
      </Dock.Container>
      <Routes>
        {tenant.onboardingCompleted ? (
          <>
            <Route
              path="knowledge-base/*"
              element={<KnowledgeBaseAdminRouter />}
            />
            <Route
              path="conversations/*"
              element={<ConversationsAdminRouter />}
            />
            <Route path="settings/*" element={<AdminSettingsRouter />} />
            <Route
              path="*"
              element={<Navigate to="knowledge-base" replace />}
            />
          </>
        ) : (
          <>
            <Route
              index
              element={<Navigate to="knowledge-base/welcome" replace />}
            />
            <Route path="knowledge-base/welcome" element={<WelcomeView />} />
            <Route
              path="knowledge-base/appearance"
              element={<AppearanceView />}
            />
            <Route
              path="knowledge-base/learn-articles"
              element={<LearnArticleView />}
            />
            <Route
              path="knowledge-base/learn-sections"
              element={<LearnSectionView />}
            />
            <Route
              path="knowledge-base/add-agents"
              element={<AddAgentsView />}
            />
            <Route
              path="knowledge-base/finish-onboarding"
              element={<FinishView />}
            />
            <Route
              path="*"
              element={<Navigate to="knowledge-base/welcome" replace />}
            />
          </>
        )}
      </Routes>
    </Page.Container>
  );
});
