import React, { createContext, useContext, useState, ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

type Alert = {
  id: number;
  message: string;
  type: "success" | "error" | "warning" | "info";
};

type AlertContextType = {
  alerts: Alert[];
  pushAlert: (
    message: string,
    type: "success" | "error" | "warning" | "info"
  ) => void;
  removeAlert: (id: number) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const theme = useTheme();
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const pushAlert = (
    message: string,
    type: "success" | "error" | "warning" | "info"
  ) => {
    const id = Date.now();
    const newAlert: Alert = {
      id,
      message,
      type,
    };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);

    // Automatically remove the alert after 3 seconds
    setTimeout(() => {
      removeAlert(id);
    }, 3000);
  };

  const removeAlert = (id: number) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ alerts, pushAlert, removeAlert }}>
      {children}
      <div style={{ position: "fixed", top: 10, right: 10, zIndex: 1000 }}>
        <AnimatePresence>
          {alerts.map((alert) => (
            <motion.div
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -100, opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              key={alert.id}
              style={{
                marginBottom: "10px",
                padding: "10px 15px",
                borderRadius: "100px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: getAlertColor(alert.type, theme),
                backgroundColor: theme.background1,
                color: getAlertColor(alert.type, theme),
              }}
            >
              <strong>{alert.type.toUpperCase()}:</strong> {alert.message}
              <button
                onClick={() => removeAlert(alert.id)}
                style={{
                  marginLeft: "10px",
                  padding: "5px 7px",
                  borderRadius: "100%",
                  border: "none",
                  backgroundColor: theme.midground1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color={theme.foreground}
                />
              </button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </AlertContext.Provider>
  );
};

const getAlertColor = (
  type: "success" | "error" | "warning" | "info",
  theme: ThemeType
) => {
  switch (type) {
    case "success":
      return theme.colorSubtleAccent || "#28a745";
    case "error":
      return "#CC3232";
    case "warning":
      return "#FFCC01";
    case "info":
      return "#FF9900";
    default:
      return "#ffffff";
  }
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
