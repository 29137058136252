/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Section } from "../../../types/knowledge-base/section";
import { Article } from "../../../types/knowledge-base/article";

export type ParamsType = {
  parent: string | null;
};
export type ResponseType = {
  sections: Section[];
  articles: Article[];
};
export const Endpoint = "section/retrieve-knowledge-base-items";
export const Method = "GET";
export const isPublic = true;
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 5,
  per: 1500,
};
export const paramsSchema = {"type":"object","properties":{"parent":{"type":["null","string"]}},"required":["parent"],"$schema":"http://json-schema.org/draft-07/schema#"}