/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
    name: string;
    mimeType: string;
    /** @deprecated */
    parentFolder?: string | null;
    extension: string;
};
export type ResponseType = {
    fsId: string;
    postOpts: { url: string; fields: any };
    /** Presigned src url */
    src: string;
};
export const Endpoint = "fs/create-file-upload";
export const Method = "POST";

export type Plug = {
    params: ParamsType;
    response: ResponseType;
    method: typeof Method;
    endpoint: typeof Endpoint;
};
export const isPublic = true;
export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"mimeType":{"type":"string"},"parentFolder":{"type":["null","string"]},"extension":{"type":"string"}},"required":["extension","mimeType","name"],"$schema":"http://json-schema.org/draft-07/schema#"}