import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPalette,
  faThLarge,
  faFileAlt,
  faUserPlus,
  faArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import { useHeaderContent } from "../../../../../../providers/header-content-provider";

const PageWrapper = styled("div", () => ({
  minHeight: "80vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MotionContainer = styled(motion.div, (props) => ({
  maxWidth: "800px",
  width: "100%",
  margin: "0 auto",
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingTop: "20px",
  textAlign: "center",
  color: props.$theme.foreground,
}));

const StepsContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "25px",
  marginBottom: "40px",
}));

const Step = styled(motion.div, () => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  maxWidth: "650px",
}));

const StepIcon = styled("div", (props) => ({
  fontSize: "24px",
  color: props.$theme.colorSubtleAccent,
  width: "150px",
  display: "flex",
  justifyContent: "center",
}));

const StepDescription = styled("div", () => ({
  textAlign: "left",
}));

const GetStartedButton = styled("button", (props) => ({
  padding: "12px 24px",
  borderRadius: "30px",
  border: "none",
  backgroundColor: props.$theme.colorSubtleAccent,
  color: props.$theme.background1,
  fontSize: "18px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: props.$theme.colorSubtleAccent,
  },
}));

const ButtonContainer = styled("div", () => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "40px",
}));

const WelcomeViewComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setHeaderContent } = useHeaderContent();

  const handleGetStarted = () => {
    navigate("/admin/dashboard/knowledge-base/appearance");
  };

  React.useEffect(() => {
    setHeaderContent(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          color: theme.foreground,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              color: theme.foreground,
              marginBottom: 0,
            }}
          >
            Welcome
          </h1>
          <p
            style={{
              opacity: 0.8,
              fontSize: 14,
              marginTop: 0,
            }}
          >
            Let's get you started with setting up your knowledge base.
          </p>
        </div>
      </div>
    );

    return () => setHeaderContent(null);
  }, [setHeaderContent, theme.foreground]);

  return (
    <PageWrapper>
      <MotionContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            marginBottom: "20px",
            color: theme.foreground,
          }}
        >
          Welcome to Your New Knowledge Base!
        </h1>
        <p
          style={{
            fontSize: "18px",
            marginBottom: "40px",
            color: theme.foreground,
          }}
        >
          Let's get you started with setting everything up. Follow these simple
          steps to get up and running.
        </p>
        <StepsContainer>
          <Step
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <StepIcon>
              <FontAwesomeIcon icon={faPalette} size="2x" />
            </StepIcon>
            <StepDescription>
              <h3>1. Set Up Your Appearance</h3>
              <p>
                Customize your logo, name, and color scheme to match your brand.
              </p>
            </StepDescription>
          </Step>
          <Step
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
          >
            <StepIcon>
              <FontAwesomeIcon icon={faThLarge} size="2x" />
            </StepIcon>
            <StepDescription>
              <h3>2. Learn About Sections</h3>
              <p>Organize your content into sections for easy navigation.</p>
            </StepDescription>
          </Step>
          <Step
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6 }}
          >
            <StepIcon>
              <FontAwesomeIcon icon={faFileAlt} size="2x" />
            </StepIcon>
            <StepDescription>
              <h3>3. Learn About Articles</h3>
              <p>Create articles to provide information to your users.</p>
            </StepDescription>
          </Step>
          <Step
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.8 }}
          >
            <StepIcon>
              <FontAwesomeIcon icon={faUserPlus} size="2x" />
            </StepIcon>
            <StepDescription>
              <h3>4. Add Your Agents</h3>
              <p>Invite team members to help manage your workspace.</p>
            </StepDescription>
          </Step>
        </StepsContainer>
        <ButtonContainer>
          <GetStartedButton onClick={handleGetStarted}>
            Get Started <FontAwesomeIcon icon={faArrowRight} />
          </GetStartedButton>
        </ButtonContainer>
      </MotionContainer>
    </PageWrapper>
  );
};

export const WelcomeView = React.memo(WelcomeViewComponent);
