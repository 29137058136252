/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Article } from "../../../types/knowledge-base/article";

export type ParamsType = { sectionUuid: string };
export type ResponseType = {
    articles: Article[];
};
export const Endpoint = "article/retrieve-articles-from-section";
export const Method = "GET";
export const isPublic = true;
export interface Plug {
    params: ParamsType;
    response: ResponseType;
    method: typeof Method;
    endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
    calls: 10,
    per: 5000,
};
export const paramsSchema = {"type":"object","properties":{"sectionUuid":{"type":"string"}},"required":["sectionUuid"],"$schema":"http://json-schema.org/draft-07/schema#"}