import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";
import { styled } from "@hiyllo/ux/styled";
import { Section } from "../../../../../../types/knowledge-base/section";

const Button = styled("button", (props) => ({
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
}));

type BreadcrumbsProps = {
  breadcrumbs: Section[];
  onBreadcrumbClick: (index: number) => void;
  onBackClick: () => void;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  onBreadcrumbClick,
  onBackClick,
}) => {
  const theme = useTheme();

  const renderBreadcrumbs = () => {
    if (breadcrumbs.length <= 3) {
      return breadcrumbs.map((crumb, index) => (
        <span key={crumb.uuid} style={{ cursor: "pointer" }}>
          {index > 0 && " > "}
          <span onClick={() => onBreadcrumbClick(index)}>{crumb.title}</span>
        </span>
      ));
    }

    // Show first breadcrumb, ellipsis, and last two breadcrumbs if there are 4 or more
    return (
      <>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => onBreadcrumbClick(0)}
        >
          {breadcrumbs[0].title}
        </span>
        {" > "}
        <span>...</span>
        {" > "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => onBreadcrumbClick(breadcrumbs.length - 2)}
        >
          {breadcrumbs[breadcrumbs.length - 2].title}
        </span>
        {" > "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => onBreadcrumbClick(breadcrumbs.length - 1)}
        >
          {breadcrumbs[breadcrumbs.length - 1].title}
        </span>
      </>
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      {breadcrumbs.length > 0 && (
        <Button
          onClick={onBackClick}
          style={{
            backgroundColor: theme.background3,
            color: theme.foreground,
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </Button>
      )}

      {breadcrumbs.length > 0 && (
        <div style={{ color: theme.foreground }}>{renderBreadcrumbs()}</div>
      )}
    </div>
  );
};
