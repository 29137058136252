import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, AnimatePresence } from "framer-motion";
import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import _ from "lodash"; // Import lodash for debounce
import { useNavigate } from "react-router-dom";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "600px",
  margin: "0 auto",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
});

const SearchInputContainer = styled(
  "div",
  (props: { $theme: ThemeType; hasResults: boolean }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor:
      props.$theme.colorScheme === "light"
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.3)",
    borderRadius: props.hasResults ? "25px 25px 0 0" : "25px",
    transition: "all 0.1s ease",
    padding: "5px 15px",
    width: "100%",
    border: `1px solid ${props.$theme.midground1}`,
    borderBottom: props.hasResults
      ? "none"
      : `1px solid ${props.$theme.midground1}`,
  })
);

interface Suggestion {
  uuid: string;
  title: string;
  icon: string;
  type: string; // Could be "section" or "article"
}

interface SearchBarProps {
  onSearch: (query: string) => Promise<Suggestion[]>; // onSearch now returns an array of objects
}

export const SearchBar: React.FC<SearchBarProps> = React.memo(
  function SearchBar({ onSearch }): JSX.Element {
    const navigate = useNavigate();
    const theme = useTheme();
    const [query, setQuery] = useState<string>("");
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]); // Now hold an array of objects with title and type
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hovered suggestion

    // Debounced function to fetch search results from the parent via onSearch
    const debouncedOnSearch = useCallback(
      _.debounce(async (searchTerm: string) => {
        if (searchTerm) {
          const results = await onSearch(searchTerm); // Fetch search results (array of {title, type})
          setSuggestions(results); // Set the search results as suggestions
        } else {
          setSuggestions([]); // Clear suggestions if no search term
        }
      }, 300), // Debounce time of 300ms
      [onSearch]
    );

    useEffect(() => {
      debouncedOnSearch(query);
      return () => {
        debouncedOnSearch.cancel(); // Cleanup debounce on unmount
      };
    }, [query]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value); // Update query state
    };

    const clearInput = () => {
      setQuery("");
      setSuggestions([]); // Clear suggestions when input is cleared
    };

    return (
      <Container>
        <SearchInputContainer
          $theme={theme}
          hasResults={suggestions.length > 0}
        >
          <FontAwesomeIcon
            icon={faSearch}
            style={{ color: theme.foregroundInactive }}
          />
          <input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={handleInputChange}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              flex: 1,
              padding: "10px",
              fontSize: "16px",
              color: theme.foreground,
              width: "100%",
            }}
          />
          {query && (
            <button
              onClick={clearInput}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0 10px",
                fontSize: "18px",
                color: theme.foregroundInactive,
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </SearchInputContainer>

        <AnimatePresence>
          {suggestions.length > 0 && (
            <motion.ul
              key="suggestions-list"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              style={{
                listStyle: "none",
                margin: "0",
                padding: "5px 15px",
                backgroundColor:
                  theme.colorScheme === "light"
                    ? "rgba(255, 255, 255, 0.3)"
                    : "rgba(0, 0, 0, 0.3)",
                borderRadius: "0 0 25px 25px",
                position: "absolute",
                top: "100%",
                width: "100%",
                zIndex: 1000,
                border: `1px solid ${theme.midground1}`,
                borderTop: "none",
                maxHeight: "200px",
                overflowY: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                backdropFilter: "blur(10px)",
              }}
            >
              {suggestions.map((suggestion, index) => (
                <div key={index}>
                  <li
                    onClick={() =>
                      navigate(`/${suggestion.type}/${suggestion.uuid}`)
                    }
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                      backgroundColor:
                        hoveredIndex === index
                          ? theme.colorScheme === "light"
                            ? "rgba(255, 255, 255, 0.3)"
                            : "rgba(0, 0, 0, 0.3)"
                          : "transparent",
                      borderRadius: "8px",
                    }}
                  >
                    {/* Conditionally render based on suggestion type */}
                    {suggestion.type === "section"
                      ? "Section"
                      : "Article"} - {suggestion.title}
                  </li>
                </div>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </Container>
    );
  }
);
