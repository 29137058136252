import { faEmptySet } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import React from "react";

type NoContentProps = {
  plug: string;
};

const NoContentContainer = styled("div", (props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: props.$theme.foreground,

  height: "80%",
  gap: "10px",
}));

const NoContent: React.FC<NoContentProps> = ({ plug }) => {
  return (
    <NoContentContainer>
      <FontAwesomeIcon icon={faEmptySet} size="2x" />
      <p>{plug}</p>
    </NoContentContainer>
  );
};

export default NoContent;
