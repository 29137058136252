/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { AgentInviteType } from "../../types/agent-invites";

export type ParamsType = {};
export type ResponseType = { invites: AgentInviteType[] };
export const Endpoint = "onboarding/fetch-agent-invites";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 5,
  per: 1500,
};
export const paramsSchema = {"type":"object","properties":{},"$schema":"http://json-schema.org/draft-07/schema#"}