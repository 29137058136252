import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";
import { styled } from "@hiyllo/ux/styled";
import { client } from "../../../../../../singletons/moopsy-client";
import * as RetrieveAllSectionsBP from "../../../../../../blueprints/knowledge-base/section/retrieve-all-sections";
import { useNavigate } from "react-router-dom";
import { SectionView } from "../section-view";

const Container = styled("div", (props) => ({
  margin: "0 auto",
  overflowY: "auto",
}));

const ExplanatoryText = styled("div", (props) => ({
  backgroundColor: props.$theme.background3,
  borderRadius: "8px",
  padding: "20px",
  marginBottom: "24px",
  color: props.$theme.foreground,
  lineHeight: "1.6",
  margin: "20px 20px",
}));

const LearnSectionViewComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [hasSections, setHasSections] = React.useState(false);

  // Fetch all sections
  const getAllSectionsQuery = client.useQuery<RetrieveAllSectionsBP.Plug>(
    RetrieveAllSectionsBP,
    {}
  );

  React.useEffect(() => {
    const allSections = getAllSectionsQuery.data?.sections ?? [];
    if (allSections.length > 1 && !hasSections) {
      setHasSections(true);
    }
  }, [getAllSectionsQuery.data?.sections, hasSections]);

  return (
    <Container>
      <ExplanatoryText>
        <h2 style={{ marginTop: 0 }}>Lets create your first Section</h2>
        <p>
          Sections help you organize your content into logical groups. You can
          create sections for different topics, projects, or any categorization
          that suits your needs. You can also drag and drop sections to reorder
          them. The order of sections will be reflected in user view.
        </p>
        <p>
          <strong>Sub-Sections:</strong> Each section can contain sub-sections,
          allowing you to create a hierarchical structure. This helps in
          breaking down complex topics into manageable parts. You can view and
          create sub-sections by clicking on a section.
        </p>
        <p>
          Use the <FontAwesomeIcon icon={faPlus} size="sm" />{" "}
          <strong>Add Section</strong> button below to create your first
          section.
        </p>
      </ExplanatoryText>
      <SectionView />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: hasSections ? 0 : 55,
          paddingBottom: hasSections ? 25 : 55,
        }}
      >
        {hasSections ? (
          <button
            style={{
              backgroundColor: theme.colorSubtleAccent,
              color: theme.foreground,
              padding: "8px 16px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/admin/dashboard/knowledge-base/add-agents");
            }}
          >
            Continue to Add Agents
          </button>
        ) : (
          <button
            style={{
              backgroundColor: theme.colorWarning,
              color: theme.foreground,
              padding: "8px 16px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.3s ease",
            }}
          >
            Skip, I'll do this later
          </button>
        )}
      </div>
    </Container>
  );
};

export const LearnSectionView = React.memo(LearnSectionViewComponent);
