/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { TenantType } from "../types/tenant";

export type ParamsType = null;
export type ResponseType = { tenant: TenantType };
export const Endpoint = "get-tenant";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 3,
  per: 2000,
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}