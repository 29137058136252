import React from "react";
import { useParams } from "react-router-dom";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { LoadingSpinnerView } from "@hiyllo/ux/loading-spinner";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import * as GetConversationBP from "../../../../blueprints/admin/conversations/get-conversation";
import * as ReplyToConversationBP from "../../../../blueprints/admin/conversations/reply-to-conversation";

import { client } from "../../../../singletons/moopsy-client";
import { styled } from "@hiyllo/ux/styled";
import { Typography } from "@hiyllo/ux/typography";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { Button } from "@hiyllo/ux/button";
import { useShowAlert } from "@hiyllo/ux/dialogs";

const Container = styled("div", ({ $theme }) => ({
  padding: 20,
  color: $theme.foreground,
}));

export const ConversationView = React.memo(
  function ConversationView(): JSX.Element {
    const { uuid } = useParams<{ uuid: string }>();
    const showAlert = useShowAlert();

    if (uuid == null) {
      throw new Error("uuid is required");
    }

    const replyInputControl = useInputControl({});
    const conversationQuery = client.useQuery<GetConversationBP.Plug>(
      GetConversationBP,
      { uuid }
    );
    const replyMutation = client.useMutation<ReplyToConversationBP.Plug>(
      ReplyToConversationBP,
      { querySideEffects: [conversationQuery] }
    );

    const sendReply = React.useCallback(() => {
      replyMutation
        .call({
          conversationUUID: uuid,
          content: replyInputControl.value,
        })
        .catch((err) => {
          showAlert({
            title: "Failed to send reply",
            message: err.message,
          });
        });
    }, [replyInputControl.value, replyMutation, showAlert, uuid]);

    if (conversationQuery.isLoading) {
      return <LoadingSpinnerView />;
    }

    if (conversationQuery.isError) {
      return (
        <EmptySplash
          icon={faExclamationCircle}
          label="Failed to load conversation"
          hint={conversationQuery.error.message}
        />
      );
    }

    const { conversation, messages } = conversationQuery.data;

    return (
      <Container>
        <Typography.Header>{conversation.subject}</Typography.Header>
        {messages.map((message) => (
          <div key={message.uuid}>
            <Typography.Label>{message.sender.type}</Typography.Label>
            <Typography.Paragraph>{message.content}</Typography.Paragraph>
          </div>
        ))}
        <Input multiline fullWidth {...replyInputControl.inputProps} />
        <Button
          onClick={sendReply}
          isLoading={replyMutation.isLoading}
          label="Send"
        />
      </Container>
    );
  }
);
