import React from "react";

import * as GetAllConversationsBP from "../../../../blueprints/admin/conversations/get-all-conversations";
import { client } from "../../../../singletons/moopsy-client";
import { Table, TableCell, TableRow } from "./table";
import { useNavigate } from "react-router-dom";

export const ConversationsList = React.memo(
  function ConversationsList(): JSX.Element {
    const navigate = useNavigate();
    const conversationsQuery = client.useQuery<GetAllConversationsBP.Plug>(
      GetAllConversationsBP,
      {}
    );

    if (conversationsQuery.data == null) {
      return <div />;
    }

    return (
      <div style={{ padding: 20 }}>
        <Table header={["Subject", "Status", "Customer"]}>
          {conversationsQuery.data.conversations.map((conversation) => (
            <TableRow
              key={conversation.uuid}
              onClick={() => {
                navigate(`conversation/${conversation.uuid}`);
              }}
            >
              <TableCell>{conversation.subject}</TableCell>
              <TableCell>{conversation.status}</TableCell>
              <TableCell>{conversation.external.email}</TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    );
  }
);
