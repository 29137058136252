import React, { memo } from "react";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheck,
  faPenToSquare,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { Article } from "../../../types/knowledge-base/article";

interface MultiSelectActionBarProps {
  articles: Article[];
  selectedCount: number;
  onPublish: () => void;
  onDelete: () => void;
  onAssignSection: () => void;
  onRename: () => void;
}

const ActionBar = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 20px",
  backgroundColor: props.$theme.background2,
  borderRadius: "8px",
  marginBottom: "16px",
  color: props.$theme.foreground,
}));

const ActionButton = styled("button", (props) => ({
  padding: "8px 16px",
  borderRadius: "4px",
  cursor: "pointer",
  color: props.$theme.foreground,
  backgroundColor: props.$theme.background3,
  border: "none",
  outline: "none",
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

export const MultiSelectActionBar: React.FC<MultiSelectActionBarProps> = memo(
  ({
    articles,
    selectedCount,
    onPublish,
    onDelete,
    onAssignSection,
    onRename,
  }) => {
    const allPublished = articles.every((article) => article.published);
    const allUnpublished = articles.every((article) => !article.published);
    const mixedStatus = !allPublished && !allUnpublished;

    return (
      <ActionBar>
        <span>{selectedCount} articles selected</span>
        <div style={{ display: "flex", gap: "12px" }}>
          {articles.length === 1 && (
            <ActionButton onClick={onRename}>
              <FontAwesomeIcon icon={faPencil} /> Rename
            </ActionButton>
          )}
          {!mixedStatus && (
            <ActionButton onClick={onPublish}>
              <FontAwesomeIcon icon={faCheck} />
              {allPublished ? "Unpublish" : "Publish"}
            </ActionButton>
          )}
          <ActionButton onClick={onAssignSection}>
            <FontAwesomeIcon icon={faPenToSquare} /> Assign Section
          </ActionButton>
          <ActionButton onClick={onDelete} style={{ color: "#FF6978" }}>
            <FontAwesomeIcon icon={faTrash} /> Delete
          </ActionButton>
        </div>
      </ActionBar>
    );
  }
);
