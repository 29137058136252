import React from "react";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { client } from "../singletons/moopsy-client";
import * as GetTenantBP from "../blueprints/get-tenant";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { TenantType } from "../types/tenant";

const TenantCtx = React.createContext<TenantType | null>(null);

export function useTenant(): TenantType {
    const tenant = React.useContext(TenantCtx);
    if (tenant === null) {
        throw new Error("Tenant not found");
    }
    return tenant;
}

export const TenantProvider = React.memo(function TenantProvider(props: React.PropsWithChildren): JSX.Element {
    const query = client.useQuery<GetTenantBP.Plug>(GetTenantBP, null);

    if (query.isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (query.isError) {
        return (
            <EmptySplash
                icon={faExclamationTriangle}
                label="Failed to load tenant"
                hint={query.error.message}
            />
        )
    }

    return (
        <TenantCtx.Provider value={query.data.tenant}>
            {props.children}
        </TenantCtx.Provider>
    );
});