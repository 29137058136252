import React from "react";
import { client } from "../../../../singletons/moopsy-client";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { Button } from "@hiyllo/ux/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faImage,
  faPalette,
  faSignature,
} from "@fortawesome/pro-solid-svg-icons";
import { uploadFileToS3 } from "../../../../features/fs";
import * as CreateFileUploadBP from "../../../../blueprints/fs/create-file-upload";
import { useTenant } from "../../../../providers/tenant-provider";
import { Typography } from "@hiyllo/ux/typography";
import { ColorPicker } from "../../../../components/color-picker";
import * as GetImageSrcBP from "../../../../blueprints/fs/get-image-src";
import * as ModifyAppearanceBP from "../../../../blueprints/admin/settings/appearance/modify-appearance";
import { useAlert } from "../../../../providers/alert-provider";

const Container = styled("div", (props) => ({
  margin: "20px 20px",
  color: props.$theme.foreground,
}));

const FormGroup = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "200px",
});

const Label = styled("div", (props) => ({
  fontWeight: "600",
  color: props.$theme.foreground,
  display: "flex",
  alignItems: "center",
  gap: "8px",
  fontSize: "14px",
})) as React.FC<React.LabelHTMLAttributes<HTMLLabelElement>>;

const Input = styled("input", (props) => ({
  padding: "12px",
  borderRadius: "8px",
  border: `1px solid ${props.$theme.midground1}`,
  backgroundColor: props.$theme.background2,
  color: props.$theme.foreground,
  fontSize: "14px",
  transition: "border-color 0.2s ease",
  "&:focus": {
    outline: "none",
    borderColor: props.$theme.colorSubtleAccent,
  },
  width: "190px",
}));

const ColorPickerGroup = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

export const AdminAppearanceView = React.memo(
  function AdminAppearanceView(): JSX.Element {
    const theme = useTheme();
    const tenant = useTenant();
    const { pushAlert } = useAlert();

    const initialAppearance = React.useRef({
      name: tenant.appearance.name,
      logo: tenant.appearance.logo,
      primaryColor: tenant.appearance.colorScheme?.primary ?? "",
      secondaryColor: tenant.appearance.colorScheme?.secondary ?? "",
    });

    const [workspaceName, setWorkspaceName] = React.useState<string>(
      tenant.appearance.name
    );
    const [logo, setLogo] = React.useState("");
    const [logoSrc, setLogoSrc] = React.useState("");
    const [logoFsId, setLogoFsId] = React.useState("");
    const [primaryColor, setPrimaryColor] = React.useState<string>(
      tenant.appearance.colorScheme?.primary ?? ""
    );
    const [secondaryColor, setSecondaryColor] = React.useState<string>(
      tenant.appearance.colorScheme?.secondary ?? ""
    );
    const [showPrimaryPicker, setShowPrimaryPicker] =
      React.useState<boolean>(false);
    const [showSecondaryPicker, setShowSecondaryPicker] =
      React.useState<boolean>(false);

    const createFileUploadMutation =
      client.useMutation<CreateFileUploadBP.Plug>(CreateFileUploadBP);

    const getSrcFromIdMutation =
      client.useMutation<GetImageSrcBP.Plug>(GetImageSrcBP);
    const modifyAppearanceMutation =
      client.useMutation<ModifyAppearanceBP.Plug>(ModifyAppearanceBP);

    React.useEffect(() => {
      if (tenant.appearance.logo) {
        getSrcFromIdMutation
          .call({ fsId: tenant.appearance.logo })
          .then((src) => {
            setLogoSrc(src.imageSrc);
            setLogoFsId(tenant.appearance.logo);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasAppearanceChanged = React.useCallback(() => {
      return (
        workspaceName !== initialAppearance.current.name ||
        (logoFsId && logoFsId !== initialAppearance.current.logo) ||
        primaryColor !== initialAppearance.current.primaryColor ||
        secondaryColor !== initialAppearance.current.secondaryColor
      );
    }, [workspaceName, logoFsId, primaryColor, secondaryColor]);

    const handleSave = React.useCallback(() => {
      if (!hasAppearanceChanged()) {
        pushAlert("No changes to save", "info");
        return;
      }

      try {
        modifyAppearanceMutation
          .call({
            name: workspaceName,
            logo: logoFsId,
            primaryColor,
            secondaryColor,
          })
          .finally(() => {
            pushAlert("Appearance settings saved", "success");
          });
      } catch (e) {
        console.error(e);
      }
    }, [
      hasAppearanceChanged,
      logoFsId,
      modifyAppearanceMutation,
      primaryColor,
      pushAlert,
      secondaryColor,
      workspaceName,
    ]);

    const handleImageUpload = React.useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        const image = e.target.files?.[0];
        if (!image) return;

        setLogo(URL.createObjectURL(image));

        const uploadRes = await createFileUploadMutation.call({
          name: "image",
          mimeType: image.type,
          extension: image.type.split("/")[1],
        });

        await uploadFileToS3(
          new File([image], "image"),
          uploadRes.postOpts,
          () => {}
        );

        setLogoFsId(uploadRes.fsId);
      },
      [createFileUploadMutation]
    );

    return (
      <Container>
        <div style={{ marginBottom: 20 }}>
          <Typography.Header>Change your appearance</Typography.Header>
          <Typography.SubHeader>
            Customize your workspace with your brand colors and logo
          </Typography.SubHeader>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <FormGroup>
            <Label htmlFor="workspaceName">
              <FontAwesomeIcon icon={faSignature} />
              Workspace Name
            </Label>
            <Input
              id="workspaceName"
              type="text"
              value={workspaceName ?? ""}
              onChange={(e: any) => setWorkspaceName(e.target.value)}
              placeholder="Name"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="logoUpload">
              <FontAwesomeIcon icon={faImage} />
              Workspace Logo
            </Label>
            <input
              id="logoUpload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            <Button
              label={
                logo || logoSrc ? (
                  <>
                    <img
                      src={logo || logoSrc}
                      alt="Logo Preview"
                      style={{
                        width: "24px",
                        height: "24px",
                        objectFit: "cover",
                      }}
                    />
                    {"Change Logo"}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faImage} />
                    Upload Logo
                  </>
                )
              }
              onClick={() => document.getElementById("logoUpload")?.click()}
              style={{
                background: theme.background2,
                border: `1px solid ${theme.midground1}`,
                color: theme.foreground,
                width: "fit-content",
                minWidth: "185px",
                maxWidth: "185px",
                height: "40px",
                textAlign: "left",
                display: "flex",
                justifyContent: "flex-start",
              }}
              inactiveColor="background2"
            />
          </FormGroup>

          <FormGroup style={{ marginBottom: 0 }}>
            <Label htmlFor="primaryColor">
              <FontAwesomeIcon icon={faPalette} />
              Primary Brand Color
            </Label>
            <ColorPickerGroup>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => setShowPrimaryPicker(true)}
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                    backgroundColor: primaryColor ? primaryColor : "#FF15D9",
                    borderRadius: "4px",
                    marginLeft: ".5em",
                  }}
                ></div>
              </div>
              {showPrimaryPicker && (
                <ColorPicker
                  defaultColor={primaryColor || "#FF15D9"}
                  onClose={() => setShowPrimaryPicker(false)}
                  onChange={(color) => {
                    setPrimaryColor(color);
                  }}
                />
              )}
              <Input
                type="text"
                value={primaryColor || ""}
                onChange={(e: any) => setPrimaryColor(e.target.value)}
                placeholder="#FF15D9"
              />
            </ColorPickerGroup>
          </FormGroup>

          <FormGroup style={{ marginBottom: 0 }}>
            <Label htmlFor="secondaryColor">
              <FontAwesomeIcon icon={faPalette} />
              Secondary Brand Color
            </Label>
            <ColorPickerGroup>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => setShowSecondaryPicker(true)}
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                    backgroundColor: secondaryColor
                      ? secondaryColor
                      : "#FE8E00",
                    borderRadius: "4px",
                    marginLeft: ".5em",
                  }}
                ></div>
              </div>
              {showSecondaryPicker && (
                <ColorPicker
                  defaultColor={secondaryColor || "#FE8E00"}
                  onClose={() => setShowSecondaryPicker(false)}
                  onChange={(color) => {
                    setSecondaryColor(color);
                  }}
                />
              )}
              <Input
                type="text"
                value={secondaryColor || ""}
                onChange={(e: any) => setSecondaryColor(e.target.value)}
                placeholder="#FE8E00"
              />
            </ColorPickerGroup>
          </FormGroup>
        </div>
        <Button
          label={
            <>
              <FontAwesomeIcon icon={faSave} />
              Save Changes
            </>
          }
          onClick={handleSave}
          style={{ width: "fit-content", marginTop: 20 }}
        />
      </Container>
    );
  }
);
