/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { email: string };
export type ResponseType = { exists: boolean; username: string | null };
export const Endpoint = "onboarding/check-agent-email";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 5,
  per: 1500,
};
export const paramsSchema = {"type":"object","properties":{"email":{"type":"string"}},"required":["email"],"$schema":"http://json-schema.org/draft-07/schema#"}