import React from "react";
import { ThemeProvider, ThemeType } from "@hiyllo/ux/theme";

export const HiylloTokyoThemeDark: ThemeType = {
  buttonForeground: "white",
  buttonBackground: "linear-gradient(to right, rgb(254 2 254), rgb(254 143 1))",
  background1: "#101014",
  background2: "#17161B",
  background3: "#201f24",
  midground: "#37363a",
  midground1: "#37363a",
  midground2: "#4d4c50",
  foreground: "#ffffff",
  foregroundInactive: "#656570",
  surfaceShadow: "rgba(0, 0, 0, 0.25) 0px 0px 20px 0px",
  colorScheme: "dark",
  colorSubtleAccent: "#0798f2",
  colorWarning: "#E44949",
};

export const HiylloTokyoThemeLight: ThemeType = {
  buttonForeground: "#1A1A1A", // Slightly softer black for contrast
  buttonBackground: "linear-gradient(90deg, #FF6B6B 0%, #FFD93D 100%)", // Vibrant gradient for that pop
  background1: "#FFFFFF", // Clean, crisp white background
  background2: "#F4F4F4", // Light gray for subtle sections
  background3: "#EAEAEA", // Even softer contrast with light gray
  midground: "#DDDDDD", // Soft midground, keeping things minimal
  midground1: "#CCCCCC", // A consistent light gray tone
  midground2: "#BBBBBB", // Slightly darker for depth contrast
  foreground: "#1A1A1A", // Darker for text, clean and sharp readability
  foregroundInactive: "#9B9B9B", // Light gray for inactive elements
  surfaceShadow: "rgba(0, 0, 0, 0.05) 0px 4px 12px 0px", // Softer, cleaner shadow for modern UI feel
  colorScheme: "light",
  colorSubtleAccent: "#3A86FF", // Cool blue accent for subtleness
  colorWarning: "#FF5959", // Slightly softer red for warnings
};


export const UXThemeProvider = React.memo(function UXThemeProvider(
  props: React.PropsWithChildren
): JSX.Element {
  const currentTheme =
    localStorage.getItem("theme") === "dark"
      ? HiylloTokyoThemeDark
      : HiylloTokyoThemeLight;
  return <ThemeProvider theme={currentTheme}>{props.children}</ThemeProvider>;
});
