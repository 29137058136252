import React from "react";
import { useTheme } from "@hiyllo/ux/theme";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

import { useTenant } from "../providers/tenant-provider";

export const Login = React.memo(function Login(): JSX.Element {
  const theme = useTheme();
  const tenant = useTenant();

  console.log(">>> tenant", JSON.stringify(tenant));

  React.useEffect(() => {
    const origin =
      window.location.host === "localhost:3000"
        ? "https://www.hiyllo.cloud"
        : `https://${tenant.federation}.f.hiyllo.cloud`;
    window.location.href = `${origin}/ia/${
      window.location.host
    }?returnRedirect=${encodeURIComponent(window.location.href)}`;
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme.background1,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingSpinner />
    </div>
  );
});
