import React, { ReactElement } from "react";
import { styled } from "@hiyllo/ux/styled";

export const TableContainer = styled<"div", { $cols: number }>(
  "div",
  ({ $theme, $cols }) => ({
    color: $theme.foreground,
    display: "grid",
    gridTemplateColumns: `repeat(${$cols}, auto)`,
    rowGap: 1,
    background: $theme.background3,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: $theme.background3,
    borderRadius: 10,
    overflow: "hidden",
  })
);

export const TableCell = styled<"div", { $header?: boolean }>(
  "div",
  ({ $theme, $header }) => ({
    background: $theme.background1,
    height: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    gap: 10,
    cursor: "pointer",
    ...($header
      ? {
          position: "sticky",
          top: 0,
          fontWeight: "bold",
        }
      : {}),
  })
);

export const TableRow = styled("div", {
  display: "contents",
});

export const Table = React.memo(function Table(props: {
  children: ReactElement<typeof TableRow>[];
  header: (JSX.Element | string)[];
}): JSX.Element {
  console.log(">>>", props.children[0]);

  if (props.children?.[0] == null) {
    return <div />;
  }

  // @ts-expect-error ---
  const cols: number = props.children[0].props.children.length;

  return (
    <TableContainer $cols={cols}>
      {props.header.map((header) => (
        <TableCell $header>{header}</TableCell>
      ))}
      {props.children}
    </TableContainer>
  );
});
