/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { OutgoingEmailAddress } from "../../../../types/settings/outgoing-email-address";

export type ParamsType = null;
export type ResponseType = {
  addresses: OutgoingEmailAddress[];
};
export const Endpoint = "admin/settings/email/get-outgoing-email-addresses";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}