import React from "react";
import {
  faCheck,
  faCog,
  faSortDown,
  faSortUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import { motion } from "framer-motion";
import { Article } from "../../../../../../types/knowledge-base/article";
import { useNavigate } from "react-router-dom";
import { ArticleTableCell } from "./render-article-cell";

const TableContainer = styled("div", {
  width: "100%",
  borderRadius: "4px",
  overflowY: "auto",
  maxHeight: "90vh",
});

const TableBody = styled("div", {
  overflowY: "auto",
});

const CustomCheckbox = styled(
  "div",
  (props: { $checked: boolean; $theme: ThemeType }) => ({
    width: "20px",
    height: "20px",
    border: `2px solid ${props.$theme.colorSubtleAccent}`,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: props.$checked
      ? props.$theme.colorSubtleAccent
      : "transparent",
    transition: "background-color 0.2s ease",
  })
);

const TableRow = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.$theme.background3,
  transition: "background-color 0.3s ease",
  borderBottom: `1px solid ${props.$theme.midground1}`,
  "&:last-child": {
    borderBottom: "none",
  },
  overflow: "scroll",
}));

const TableHeader = styled(TableRow, (props) => ({
  fontWeight: "bold",
  backgroundColor: props.$theme.background2,
  position: "sticky",
  top: 0,
}));

const TableCell = styled("div", (props) => ({
  flex: 1,
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  color: props.$theme.foreground,
}));

const CheckboxCell = styled(TableCell, {
  flex: "0 0 0px",
});

export const TableComponent: React.FC<{
  data: Article[];
  selectedArticles: Article[];
  setSelectedArticles: React.Dispatch<React.SetStateAction<Article[]>>;
}> = React.memo(({ data, selectedArticles, setSelectedArticles }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [sortColumn, setSortColumn] = React.useState<string>("updatedAt");
  const [sortDirection, setSortDirection] = React.useState<string>("desc");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [editingArticle, setEditingArticle] = React.useState<Article | null>(
    null
  );
  const cogIconRef = React.useRef<HTMLDivElement>(null);
  const [cogPosition, setCogPosition] = React.useState<{
    top: number;
    left: number;
  }>({
    top: 0,
    left: 0,
  });
  const [showColumnSettings, setShowColumnSettings] =
    React.useState<boolean>(false);
  const [sortClickCount, setSortClickCount] = React.useState<{
    [key: string]: number;
  }>({
    title: 0,
    updatedAt: 0,
    createdAt: 0,
    published: 0,
    author: 0,
  });
  const [visibleColumns, setVisibleColumns] = React.useState<{
    [key: string]: boolean;
  }>({
    title: true,
    updatedAt: true,
    createdAt: false,
    published: false,
    author: false,
  });

  const columns = [
    { key: "title", label: "Title", sortable: true },
    { key: "updatedAt", label: "Last Edited", sortable: true },
    { key: "createdAt", label: "Created At", sortable: true },
    { key: "published", label: "Published", sortable: true },
    { key: "author", label: "Author", sortable: false },
  ];

  const sortedArticles = React.useMemo(() => {
    return [...data].sort((a, b) => {
      if (sortColumn === "") return 0;
      const aValue = a[sortColumn as keyof Article];
      const bValue = b[sortColumn as keyof Article];

      if (aValue instanceof Date && bValue instanceof Date) {
        return sortDirection === "asc"
          ? aValue.getTime() - bValue.getTime()
          : bValue.getTime() - aValue.getTime();
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
        return sortDirection === "asc"
          ? Number(aValue) - Number(bValue)
          : Number(bValue) - Number(aValue);
      }
      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  const filteredArticles = React.useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return sortedArticles.filter((article: Article) => {
      return (
        article.title.toLowerCase().includes(lowerCaseSearchTerm) ||
        article.content.descendants.some(
          (desc: any) =>
            desc.type === "paragraph" &&
            desc.children.some((child: any) =>
              child.text.toLowerCase().includes(lowerCaseSearchTerm)
            )
        )
      );
    });
  }, [sortedArticles, searchTerm]);

  const handleSort = React.useCallback(
    (column: string): void => {
      setSortClickCount((prevCount) => ({
        ...prevCount,
        [column]: (prevCount[column] + 1) % 3,
      }));

      if (sortClickCount[column] === 1) {
        setSortColumn(column);
        setSortDirection("desc");
      } else if (sortClickCount[column] === 2) {
        setSortColumn("");
        setSortDirection("");
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    },
    [sortClickCount]
  );

  const toggleColumnVisibility = React.useCallback((columnKey: string) => {
    setVisibleColumns((prevColumns) => ({
      ...prevColumns,
      [columnKey]: !prevColumns[columnKey],
    }));
  }, []);

  const handleSelectAll = React.useCallback((): void => {
    if (selectedArticles.length === filteredArticles.length) {
      setSelectedArticles([]);
    } else {
      setSelectedArticles(filteredArticles);
    }
  }, [selectedArticles.length, filteredArticles, setSelectedArticles]);

  const handleSelectArticle = React.useCallback(
    (article: Article): void => {
      setSelectedArticles((prev) =>
        prev.some((a) => a.uuid === article.uuid)
          ? prev.filter((a) => a.uuid !== article.uuid)
          : [...prev, article]
      );
    },
    [setSelectedArticles]
  );

  const updateCogPosition = React.useCallback(() => {
    if (cogIconRef.current) {
      const rect = cogIconRef.current.getBoundingClientRect();
      const panelWidth = 175;
      const viewportWidth = window.innerWidth;

      let left = rect.left;
      if (left + panelWidth > viewportWidth) {
        left = viewportWidth - panelWidth;
      }

      setCogPosition({
        top: rect.bottom + 20,
        left,
      });
    }
  }, []);

  React.useEffect(() => {
    updateCogPosition();
    window.addEventListener("resize", updateCogPosition);
    return () => window.removeEventListener("resize", updateCogPosition);
  }, [updateCogPosition]);

  const toggleColumnSettings = () => {
    setShowColumnSettings(!showColumnSettings);
    updateCogPosition();
  };

  return (
    <>
      <TableContainer>
        <TableHeader>
          <CheckboxCell>
            <CustomCheckbox
              $checked={selectedArticles.length === filteredArticles.length}
              $theme={theme}
              onClick={handleSelectAll}
            >
              {selectedArticles.length === filteredArticles.length && (
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ color: theme.background1 }}
                />
              )}
            </CustomCheckbox>
          </CheckboxCell>
          {columns.map((column) =>
            visibleColumns[column.key] ? (
              <TableCell
                key={column.key}
                onClick={() => column.sortable && handleSort(column.key)}
                style={{
                  cursor: column.sortable ? "pointer" : "default",
                }}
              >
                {column.label}
                {sortColumn === column.key && (
                  <FontAwesomeIcon
                    icon={sortDirection === "asc" ? faSortUp : faSortDown}
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </TableCell>
            ) : null
          )}
          <TableCell style={{ flex: "0 0 auto" }}>
            <div ref={cogIconRef} onClick={toggleColumnSettings}>
              <FontAwesomeIcon icon={faCog} style={{ cursor: "pointer" }} />
            </div>
          </TableCell>
        </TableHeader>
        <TableBody>
          {filteredArticles.map((article) => (
            <TableRow key={article.uuid}>
              <CheckboxCell>
                <CustomCheckbox
                  $checked={selectedArticles.some(
                    (a) => a.uuid === article.uuid
                  )}
                  $theme={theme}
                  onClick={() => handleSelectArticle(article)}
                >
                  {selectedArticles.some((a) => a.uuid === article.uuid) && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.background1 }}
                    />
                  )}
                </CustomCheckbox>
              </CheckboxCell>
              {columns.map((column) =>
                visibleColumns[column.key] ? (
                  <TableCell key={column.key}>
                    <ArticleTableCell
                      article={article}
                      columnKey={column.key}
                      setEditingArticle={setEditingArticle}
                    />
                  </TableCell>
                ) : null
              )}
              {/* <FontAwesomeIcon
                icon={faEllipsisVertical}
                style={{
                  color: theme.foreground,
                  cursor: "pointer",
                }}
                onClick={() =>
                  console.log("edit" + JSON.stringify(article, null, 2))
                }
              /> */}
              <TableCell style={{ flex: "0 0 auto" }} />
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      {showColumnSettings && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.2 }}
          style={{
            position: "absolute",
            top: `${cogPosition.top}px`,
            left: `${cogPosition.left}px`,
            backgroundColor: theme.background2,
            padding: "16px",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            zIndex: 1000,
            color: theme.foreground,
          }}
        >
          <h4 style={{ marginTop: 0 }}>Select Columns</h4>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {columns.map((column) => (
              <label
                key={column.key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <CustomCheckbox
                  $checked={visibleColumns[column.key]}
                  $theme={theme}
                  onClick={() => toggleColumnVisibility(column.key)}
                >
                  {visibleColumns[column.key] && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: theme.background1 }}
                    />
                  )}
                </CustomCheckbox>
                <span style={{ marginLeft: "8px" }}>{column.label}</span>
              </label>
            ))}
          </div>
        </motion.div>
      )}
    </>
  );
});
