/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  title: string;
  description: string;
  parentSectionUUID: string | null;
  icon: string | null;
};
export type ResponseType = {
  uuid: string;
};
export const Endpoint = "section/create-section";
export const Method = "POST";
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"title":{"type":"string"},"description":{"type":"string"},"parentSectionUUID":{"type":["null","string"]},"icon":{"type":["null","string"]}},"required":["description","icon","parentSectionUUID","title"],"$schema":"http://json-schema.org/draft-07/schema#"}