import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import React from "react";

const FooterContainer = styled<"div", { $transparent?: boolean }>(
  "div",
  ({ $theme, $transparent }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    height: 50,
    flexShrink: 0,

    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "blue",
  })
);

const FooterLogo = styled("img", {
  height: 12.5,
});

const PoweredByText = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  fontSize: 12.5,
}));

export const Footer = React.memo(function Footer(props: {
  transparent?: boolean;
}): JSX.Element {
  const theme = useTheme();
  return (
    <FooterContainer $transparent={props.transparent}>
      {theme.colorScheme === "light" ? (
        <FooterLogo src="https://cdn.hiyllo.net/logo/support/black-icon.png" />
      ) : (
        <FooterLogo src="https://cdn.hiyllo.net/logo/support/white-icon.png" />
      )}
      <PoweredByText>Powered by Hiyllo Support</PoweredByText>
    </FooterContainer>
  );
});
