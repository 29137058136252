import React from "react";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { Button } from "@hiyllo/ux/button";
import { useShowAlert } from "@hiyllo/ux/dialogs";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { UseMoopsyQueryRetValAny } from "@moopsyjs/react/main";
import { LoadingSpinnerView } from "@hiyllo/ux/loading-spinner";
import { HeaderRow, Label, Typography } from "@hiyllo/ux/typography";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import * as GetOutgoingEmailAddressesBP from "../../../../blueprints/admin/settings/email/get-outgoing-email-addresses";
import * as CreateOutgoingEmailAddressBP from "../../../../blueprints/admin/settings/email/create-outgoing-email-address";
import * as VerifyOutgoingEmailAddressBP from "../../../../blueprints/admin/settings/email/verify-outgoing-email-address";

import { client } from "../../../../singletons/moopsy-client";
import { DNSRecord } from "./dns-record";
import { OutgoingEmailAddress } from "../../../../types/settings/outgoing-email-address";
import { useTenant } from "../../../../providers/tenant-provider";

const Container = styled("div", ({ $theme }) => ({
  padding: 20,
  color: $theme.foreground,
}));

const CreateOutgoingEmailAdddressView = React.memo(
  function CreateOutgoingEmailAddressView(props: {
    query: UseMoopsyQueryRetValAny;
  }): JSX.Element {
    const createAddressMutation =
      client.useMutation<CreateOutgoingEmailAddressBP.Plug>(
        CreateOutgoingEmailAddressBP,
        { querySideEffects: [props.query] }
      );
    const usernameInputControl = useInputControl({});
    const domainInputControl = useInputControl({});
    const showAlert = useShowAlert();

    const create = React.useCallback(() => {
      const username = usernameInputControl.value;
      const domain = domainInputControl.value;

      if (username.length < 3) {
        return showAlert({
          title: "Invalid username",
          message: "Username must be at least 3 characters",
        });
      }

      if (domain.length < 4 || !domain.includes(".")) {
        return showAlert({
          title: "Invalid domain",
          message: "Domain must be at least 3 characters",
        });
      }

      createAddressMutation.call({ username, domain }).catch((err) => {
        showAlert({
          title: "Failed to create email address",
          message: err.message,
        });
      });
    }, [
      createAddressMutation,
      domainInputControl.value,
      showAlert,
      usernameInputControl.value,
    ]);

    return (
      <Container>
        <Typography.Header>Onboard an email address</Typography.Header>
        <Typography.Paragraph>
          Once complete, you&apos;ll be able to receive and reply to
          conversations via email
        </Typography.Paragraph>

        <div style={{ height: 20 }} />

        <div>
          <Label>Address</Label>
          <HeaderRow>
            <Input
              outerStyle={{ width: 150 }}
              inputStyle={{ textAlign: "right" }}
              placeholder="taylor"
              {...usernameInputControl.inputProps}
            />
            @
            <Input
              placeholder="hiyllo.com"
              {...domainInputControl.inputProps}
            />
          </HeaderRow>
        </div>

        <div style={{ height: 20 }} />

        <Typography.HeaderRow>
          <Button
            label="Create"
            onClick={create}
            isLoading={createAddressMutation.isLoading}
          />
        </Typography.HeaderRow>
      </Container>
    );
  }
);

const AddressCard = React.memo(function AddressCard({
  address,
  query,
}: {
  address: OutgoingEmailAddress;
  query: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const [domainCheckError, setDomainCheckError] = React.useState<string | null>(
    null
  );

  const verifyMutation = client.useMutation<VerifyOutgoingEmailAddressBP.Plug>(
    VerifyOutgoingEmailAddressBP,
    { querySideEffects: [query] }
  );

  const verifyAddress = React.useCallback(() => {
    verifyMutation.call({ uuid: address.uuid }).catch((err) => {
      setDomainCheckError(err.message);
    });
  }, [address.uuid, verifyMutation]);
  const tenant = useTenant();

  return (
    <>
      <Typography.Header>
        {address.username}@{address.domain}
      </Typography.Header>
      {address.verified ? (
        <div style={{ userSelect: "none" }}>
          You're ready to go! To start receiving support requests, configure
          your current email provider to forward support emails to{" "}
          <span style={{ userSelect: "all" }}>
            {tenant.tenantId}@hiyllo.support
          </span>
        </div>
      ) : (
        <>
          <Typography.SubHeader>
            Setup required before you can use this address
          </Typography.SubHeader>
          <div style={{ height: 10 }} />
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <DNSRecord
              type="TXT"
              host="@"
              value="v=spf1 include:spf.hiyllo.network -all"
            />
            <DNSRecord
              type="TXT"
              host="@"
              value={"hylosp=" + window.location.host}
            />
            <DNSRecord
              type="TXT"
              host={address.dkimRecord.keySelector + "._domainkey"}
              value={
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                "v=DKIM1; k=rsa; p=" + address.dkimRecord.publicKey
              }
            />
          </div>
          <div style={{ height: 10 }} />
          <Typography.Label>
            If your domain provider does not let you enter {'"@"'} as the host,
            leave the field blank
          </Typography.Label>
          <Typography.Label>
            Hiyllo has your back. If you need help email support@hiyllo.io
          </Typography.Label>
          <div style={{ height: 10 }} />
          <div style={{ flexShrink: 0 }}>
            <AnimateChangeInHeight>
              {domainCheckError != null ? (
                <div style={{ paddingBottom: 10, color: "red" }}>
                  {domainCheckError}. Sometimes records can take up to 24 hours
                  to propagate. Check back here later.
                </div>
              ) : (
                <div />
              )}
            </AnimateChangeInHeight>
          </div>
          <Typography.HeaderRow>
            <Button
              label="Verify"
              onClick={verifyAddress}
              // isLoading={checkEmailDomainMutation.isLoading}
            />
          </Typography.HeaderRow>
        </>
      )}
    </>
  );
});

export const AdminSettingsEmail = React.memo(
  function AdminSettingsEmail(): JSX.Element {
    const addressesQuery = client.useQuery<GetOutgoingEmailAddressesBP.Plug>(
      GetOutgoingEmailAddressesBP,
      null
    );

    if (addressesQuery.isLoading) {
      return <LoadingSpinnerView />;
    }

    if (addressesQuery.isError) {
      return (
        <EmptySplash
          icon={faExclamationCircle}
          label="Failed to load email addresses"
          hint={addressesQuery.error.message}
        />
      );
    }

    const { addresses } = addressesQuery.data;

    if (addresses.length === 0) {
      return <CreateOutgoingEmailAdddressView query={addressesQuery} />;
    }

    const address = addresses[0];

    return (
      <Container>
        <AddressCard address={address} query={addressesQuery} />
      </Container>
    );
  }
);
