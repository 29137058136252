/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DocumentContentsV2 } from "../../../types/knowledge-base/article";

export type ParamsType = {
  uuids: string[];
  title?: string;
  parentSectionUUID?: string;
  published?: boolean;
};
export type ResponseType = {};
export const Endpoint = "article/update-articles";
export const Method = "POST";
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"uuids":{"type":"array","items":{"type":"string"}},"title":{"type":"string"},"parentSectionUUID":{"type":"string"},"published":{"type":"boolean"}},"required":["uuids"],"$schema":"http://json-schema.org/draft-07/schema#"}