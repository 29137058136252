/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserType } from "../../types/users/user";

export type ParamsType = {
  username: string;
  invitorId: string;
  email: string;
};
export type ResponseType = {
  tenantId: string;
  user: UserType; // <- why
};
export const Endpoint = "onboarding/invite-agent";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const isPublic = true;

export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"username":{"type":"string"},"invitorId":{"type":"string"},"email":{"type":"string"}},"required":["email","invitorId","username"],"$schema":"http://json-schema.org/draft-07/schema#"}