import React, { useState, useEffect } from "react";
import { useTheme } from "@hiyllo/ux/theme";
import { styled } from "@hiyllo/ux/styled";
import * as RetrieveAllSectionsBP from "../../../blueprints/knowledge-base/section/retrieve-all-sections";
import * as RetrieveSectionBP from "../../../blueprints/knowledge-base/section/retrieve-section";
import { client } from "../../../singletons/moopsy-client";
import { Section } from "../../../types/knowledge-base/section";

const ModalBackground = styled("div", (props) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  backdropFilter: "blur(12px)",
  WebkitBackdropFilter: "blur(12px)",
}));

const ModalContent = styled("div", (props) => ({
  backgroundColor: props.$theme.background1,
  color: props.$theme.foreground,
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "500px",
  width: "100%",
  boxShadow: `0 4px 6px ${props.$theme.background1}`,
}));

const CurrentSection = styled("div", (props) => ({
  marginBottom: "10px",
  fontSize: "16px",
  color: props.$theme.foreground,
}));

const Input = styled("input", (props) => ({
  width: "96%",
  padding: "10px",
  marginBottom: "10px",
  borderRadius: "4px",
  backgroundColor: props.$theme.background2,
  border: `1px solid ${props.$theme.midground1}`,
  fontSize: "16px",
  color: props.$theme.foreground,
  outline: "none",
}));

const ListItem = styled("div", (props) => ({
  listStyle: "none",
  padding: "10px",
  marginBottom: "5px",
  borderRadius: "4px",
  backgroundColor: props.$theme.background3,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: props.$theme.colorSubtleAccent,
  },
}));

const Button = styled("button", (props) => ({
  padding: "8px 16px",
  borderRadius: "4px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  backgroundColor: props.$theme.colorSubtleAccent,
  color: props.$theme.foreground,
  marginTop: "10px",
}));

interface SectionLookupProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSelectSection: (section: Section) => void;
  currentSection?: string | null;
}

export const SectionLookup: React.FC<SectionLookupProps> = React.memo(
  ({ isModalOpen, onClose, onSelectSection, currentSection }) => {
    const theme = useTheme();
    const [sections, setSections] = useState<Section[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    const getAllSectionsQuery = client.useQuery(RetrieveAllSectionsBP, {});

    // Conditionally execute the query only if currentSection is not null or undefined
    const getSectionMutation = currentSection
      ? client.useQuery(RetrieveSectionBP, { uuid: currentSection })
      : null;

    useEffect(() => {
      if (getAllSectionsQuery.data?.sections) {
        setSections(getAllSectionsQuery.data.sections);
      }
    }, [getAllSectionsQuery.data]);

    const filteredSections = sections.filter(
      (section: Section) =>
        section.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        section.title.toLowerCase() !== "root"
    );

    const handleSectionSelect = (section: Section) => {
      onSelectSection(section);
    };

    return (
      <>
        {isModalOpen && (
          <ModalBackground>
            <ModalContent>
              {currentSection ? (
                <CurrentSection>
                  <strong>Currently assigned to:</strong>{" "}
                  {getSectionMutation?.data?.section?.title || "Loading..."}
                </CurrentSection>
              ) : (
                <CurrentSection>
                  <strong>Currently not assigned to a Section</strong>
                </CurrentSection>
              )}
              <Input
                type="text"
                placeholder="Search sections..."
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              <ul style={{ padding: 0, margin: 0 }}>
                {filteredSections.map((section) => (
                  <ListItem
                    key={section.uuid}
                    onClick={() => handleSectionSelect(section)}
                  >
                    {section.title}
                  </ListItem>
                ))}
              </ul>
              <Button onClick={onClose}>Close</Button>
            </ModalContent>
          </ModalBackground>
        )}
      </>
    );
  }
);
