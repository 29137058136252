import { styled } from "@hiyllo/ux/styled";
import React from "react";

const CodeBlock = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 16,
  alignItems: "center",
  background: $theme.background2,
  padding: 8,
  paddingRight: 16,
  borderRadius: 8,
  width: 600,
}));

const RecordType = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  padding: 8,
  borderRadius: 4,
  userSelect: "none",
}));

const RecordValueInput = styled("input", ({ $theme }) => ({
  fontFamily: "monospace",
  userSelect: "all",
  display: "block",
  background: "none",
  border: "none",
  width: "100%",
  paddingLeft: 0,
  color: $theme.foreground,
}));

export const DNSRecord = React.memo(function DNSRecord({
  type,
  host,
  value,
  priority,
}: {
  type: string;
  host: string;
  value: string;
  priority?: string | number;
}): JSX.Element {
  return (
    <CodeBlock>
      <RecordType>{type}</RecordType>
      <div style={{ width: 120, flexShrink: 0 }}>
        <div style={{ fontSize: 10 }}>Host</div>
        <RecordValueInput
          onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
            setTimeout(() => {
              evt.target.select();
            });
          }}
          value={host}
        />
      </div>
      {priority != null ? (
        <div style={{ width: 40, flexShrink: 0 }}>
          <div style={{ fontSize: 10 }}>Priority</div>
          <RecordValueInput
            onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
              setTimeout(() => {
                evt.target.select();
              });
            }}
            value={priority}
          />
        </div>
      ) : null}
      <div style={{ width: 0, flexGrow: 1 }}>
        <div style={{ fontSize: 10 }}>Value</div>
        <RecordValueInput
          onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
            setTimeout(() => {
              evt.target.select();
            });
          }}
          value={value}
        />
      </div>
    </CodeBlock>
  );
});
