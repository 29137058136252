import { retry } from "../lib/retry";

export async function uploadFileToS3(file: File, { url, fields }: { url: string, fields: Record<string, string>; }, onProgress: (progress: number) => void): Promise<void> {
    await retry(() => new Promise<void>(async (resolve, reject) => {
        const form = new FormData();
        Object.entries(fields).forEach(([field, value]) => {
            form.append(field, value);
        });

        form.append('file', file);

        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener('progress', (event) => {
            onProgress(event.loaded / event.total);
        });

        xhr.addEventListener('error', err => {
            reject(err);
        });

        xhr.open('POST', url, true);
        xhr.send(form);

        xhr.addEventListener('load', () => {
            resolve();
        });
    }), 3);
}