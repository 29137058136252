import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "@hiyllo/ux/tokyo";
import { faFiles, faFolders } from "@fortawesome/pro-solid-svg-icons";
import { SupportAdminSidebar } from "../../admin/sidebar";
import { ConversationsList } from "./views/conversations-list";
import { ConversationView } from "./views/conversation";

export const ConversationsAdminRouter = React.memo(
  function ConversationsAdminRouter(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <>
        <SupportAdminSidebar>
          <Sidebar.Header label="Conversations" />

          <Sidebar.Button
            isActive={location.pathname.includes("articles")}
            icon={{ fa: faFiles }}
            onClick={() => {
              navigate("articles");
            }}
            label="Articles"
          />
          <Sidebar.Button
            isActive={location.pathname.includes("sections")}
            icon={{ fa: faFolders }}
            onClick={() => {
              navigate("sections");
            }}
            label="Sections"
          />
        </SupportAdminSidebar>
        <div style={{ width: 0, flexGrow: 1 }}>
          <Routes>
            <Route path="conversation/:uuid" element={<ConversationView />} />
            <Route path="*" element={<ConversationsList />} />
          </Routes>
        </div>
      </>
    );
  }
);
