import React, { CSSProperties } from "react";
import { Section } from "../../../../../../types/knowledge-base/section";
import { motion } from "framer-motion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faGripVertical,
  faEdit,
  faTrash,
  faFolder,
  faMemoPad,
  faFile,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";
import { ArticleCard } from "./article-card";
import { styled } from "@hiyllo/ux/styled";
import * as DeleteSectionBP from "../../../../../../blueprints/knowledge-base/section/delete-section";
import * as UpdateSectionBP from "../../../../../../blueprints/knowledge-base/section/update-section";
import * as UpdateSectionOrderBP from "../../../../../../blueprints/knowledge-base/section/update-section-order";
import * as RetrieveAllSectionsBP from "../../../../../../blueprints/knowledge-base/section/retrieve-all-sections";
import * as RetrieveSectionBP from "../../../../../../blueprints/knowledge-base/section/retrieve-section";
import * as RetrieveArticlesFromSectionBP from "../../../../../../blueprints/knowledge-base/article/retrieve-articles-from-section";
import * as CreateSectionBP from "../../../../../../blueprints/knowledge-base/section/create-section";
import { client } from "../../../../../../singletons/moopsy-client";
import { Article } from "../../../../../../types/knowledge-base/article";
import NoContent from "../../../../../../components/no-content";
import { IconDisplay } from "../../../../../../features/knowledge-base/components/icon-display";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

export const Button = styled("button", (props) => ({
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
}));

const NewSectionCard = styled("div", (props) => ({
  backgroundColor: props.$theme.background3,
  borderRadius: "8px",
  padding: "20px",
  marginBottom: "16px",
  marginTop: "16px",
}));

const SectionList = ({
  showNewSectionCard,
  setNewSectionCard,
  breadcrumbs,
  setBreadcrumbs,
  currentParentUUID,
  setCurrentParentUUID,
  searchTerm,
}: {
  showNewSectionCard: boolean;
  setNewSectionCard: (value: boolean) => void;
  breadcrumbs: Section[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Section[]>>;
  currentParentUUID: string | null;
  setCurrentParentUUID: React.Dispatch<React.SetStateAction<string | null>>;
  searchTerm: string;
}) => {
  const theme = useTheme();
  const [sections, setSections] = React.useState<Section[]>([]);
  const [sectionTitle, setSectionTitle] = React.useState("");
  const [sectionDescription, setSectionDescription] = React.useState("");
  const [selectedSectionUUID, setSelectedSectionUUID] = React.useState<
    string | null
  >(null);
  const [editingSection, setEditingSection] = React.useState<Section | null>(
    null
  );
  const [articles, setArticles] = React.useState<Article[] | null>(null);
  const [selectedIcon, setSelectedIcon] = React.useState<string>("");

  const getArticlesFromSection =
    client.useMutation<RetrieveArticlesFromSectionBP.Plug>(
      RetrieveArticlesFromSectionBP,
      {}
    );

  // Fetch all sections
  const getAllSectionsQuery = client.useQuery<RetrieveAllSectionsBP.Plug>(
    RetrieveAllSectionsBP,
    {}
  );

  // Fetch the root section
  const getRootSectionQuery = client.useQuery<RetrieveSectionBP.Plug>(
    RetrieveSectionBP,
    { uuid: "root" }
  );

  const deleteSectionMutation = client.useMutation<DeleteSectionBP.Plug>(
    DeleteSectionBP,
    { querySideEffects: [getAllSectionsQuery, getRootSectionQuery] }
  );

  const updateSectionMutation = client.useMutation<UpdateSectionBP.Plug>(
    UpdateSectionBP,
    { querySideEffects: [getAllSectionsQuery] }
  );

  const updateSectionOrderMutation =
    client.useMutation<UpdateSectionOrderBP.Plug>(UpdateSectionOrderBP, {
      querySideEffects: [getAllSectionsQuery, getRootSectionQuery],
    });

  const createSectionMutation =
    client.useMutation<CreateSectionBP.Plug>(CreateSectionBP);

  React.useEffect(() => {
    const fetchSections = async () => {
      const allSections = getAllSectionsQuery.data?.sections ?? [];

      const sectionsByUuid: { [uuid: string]: Section } = {};
      allSections.forEach((section) => {
        sectionsByUuid[section.uuid] = section;
      });

      let orderedSections: Section[] = [];

      if (currentParentUUID) {
        const parentSection = sectionsByUuid[currentParentUUID];
        if (parentSection) {
          orderedSections = parentSection.subSections
            .map((uuid: string) => sectionsByUuid[uuid])
            .filter(Boolean);
        }
      } else {
        const rootSection = getRootSectionQuery.data?.section;
        if (rootSection) {
          orderedSections = rootSection.subSections
            .map((uuid: string) => sectionsByUuid[uuid])
            .filter(Boolean);
        }
      }

      setSections(orderedSections);

      if (currentParentUUID) {
        // Fetch articles for the current section
        const articlesResult = await getArticlesFromSection.call({
          sectionUuid: currentParentUUID,
        });
        setArticles(articlesResult?.articles || null);
      } else {
        setArticles(null); // Clear articles if we're back at the root
      }
    };

    fetchSections();
  }, [
    getAllSectionsQuery.data?.sections,
    getRootSectionQuery.data?.section,
    currentParentUUID,
  ]);

  const filteredSections = sections.filter((section) =>
    section.title
      ? section.title.toLowerCase().includes(searchTerm.toLowerCase())
      : false
  );

  const getChildSectionCount = (sectionUUID: string) => {
    const allSections = getAllSectionsQuery.data?.sections ?? [];
    return allSections.filter(
      (section) => section.parentSectionUUID === sectionUUID
    ).length;
  };

  const handleOnDragEnd = React.useCallback(
    async (result: any) => {
      if (!result.destination) return;
      if (result.source.index === result.destination.index) return;

      const reorderedSections = Array.from(sections);
      const [removed] = reorderedSections.splice(result.source.index, 1);
      reorderedSections.splice(result.destination.index, 0, removed);

      setSections(reorderedSections);

      // Prepare the new order of subSections UUIDs
      const newSubSections = reorderedSections.map((section) => section.uuid);

      try {
        await updateSectionOrderMutation.call({
          parentUuid: currentParentUUID || "root",
          uuid: removed.uuid,
          subSections: newSubSections,
        });
      } catch (error) {
        console.error("Failed to update section order:", error);
        setSections(sections);
      }
    },
    [sections, updateSectionOrderMutation, currentParentUUID]
  );

  const handleCreateSection = async () => {
    if (!sectionTitle) {
      alert("Please add a title for your section.");
      return;
    }

    const newSectionData = {
      title: sectionTitle,
      description: sectionDescription,
      parentSectionUUID: currentParentUUID || "root",
      icon: selectedIcon,
    };

    try {
      await createSectionMutation.call(newSectionData);

      // Refresh queries to get updated data
      getAllSectionsQuery.refresh();
      if (!currentParentUUID) {
        getRootSectionQuery.refresh();
      }

      setNewSectionCard(false);
      setSectionTitle("");
      setSectionDescription("");
      setSelectedIcon("");
    } catch (error) {
      console.error("Failed to create section:", error);
    }
  };

  const handleUpdateSection = React.useCallback(async () => {
    if (!editingSection) return;

    try {
      await updateSectionMutation.call({
        uuid: editingSection.uuid,
        title: editingSection.title,
        description: editingSection.description,
        icon: editingSection.icon as string,
      });

      setEditingSection(null);
      // Refresh queries to get updated data
      getAllSectionsQuery.refresh();
    } catch (error) {
      console.error("Failed to update section:", error);
    }
  }, [editingSection, updateSectionMutation, getAllSectionsQuery]);

  const handleSectionClick = React.useCallback(
    (section: Section) => {
      if (editingSection) return;
      setSelectedSectionUUID(section.uuid);
      setCurrentParentUUID(section.uuid);
      setBreadcrumbs((prev) => [...prev, section]);
    },
    [editingSection, setCurrentParentUUID, setBreadcrumbs]
  );

  const handleDeleteSection = React.useCallback(
    async (uuid: string) => {
      if (window.confirm("Are you sure you want to delete this section?")) {
        try {
          await deleteSectionMutation.call({ uuid });
          // Refresh queries to get updated data
          getAllSectionsQuery.refresh();
          if (!currentParentUUID) {
            getRootSectionQuery.refresh();
          }
        } catch (error) {
          console.error("Failed to delete section:", error);
        }
      }
    },
    [
      deleteSectionMutation,
      getAllSectionsQuery,
      getRootSectionQuery,
      currentParentUUID,
    ]
  );

  const handlePublish = React.useCallback(
    async (section: Section) => {
      try {
        await updateSectionMutation.call({
          uuid: section.uuid,
          published: !section.published,
        });

        setEditingSection(null);
      } catch (error) {
        console.error("Failed to update section:", error);
      }
    },
    [updateSectionMutation]
  );

  return (
    <>
      {articles && articles.length > 0 && (
        <div>
          {articles.map((article) => (
            <ArticleCard
              key={article.uuid}
              article={article}
              onClick={() =>
                console.log(`Clicked on article: ${article.title}`)
              }
            />
          ))}
        </div>
      )}
      {showNewSectionCard && (
        <NewSectionCard themeOverride={theme}>
          <h3
            style={{
              color: theme.foreground,
              marginBottom: "20px",
              fontSize: "22px",
            }}
          >
            Create a New Section
          </h3>
          <div style={{ display: "grid", gap: "16px" }}>
            <div style={{ display: "flex", gap: 15 }}>
              {selectedIcon && (
                <IconDisplay
                  iconName={selectedIcon}
                  size="lg"
                  color={theme.foreground}
                />
              )}
              <input
                type="text"
                value={selectedIcon}
                placeholder="Icon name..."
                onChange={(e) => setSelectedIcon(e.target.value)}
                style={{
                  width: "10%",
                  padding: "12px 16px",
                  backgroundColor: theme.background1,
                  borderRadius: "8px",
                  border: `1px solid ${theme.midground1}`,
                  boxShadow: `0 1px 4px rgba(0, 0, 0, 0.1)`,
                  fontSize: "16px",
                  color: theme.foreground,
                  fontFamily: "Hiyllo, sans-serif",
                  outline: "none",
                  transition: "border-color 0.2s ease",
                }}
              />
              <input
                type="text"
                placeholder="Section Title"
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
                style={{
                  width: "97%",
                  padding: "12px 16px",
                  backgroundColor: theme.background1,
                  borderRadius: "8px",
                  border: `1px solid ${theme.midground1}`,
                  boxShadow: `0 1px 4px rgba(0, 0, 0, 0.1)`,
                  fontSize: "16px",
                  color: theme.foreground,
                  fontFamily: "Hiyllo, sans-serif",
                  outline: "none",
                  transition: "border-color 0.2s ease",
                }}
                onFocus={(e) =>
                  (e.target.style.borderColor = theme.colorSubtleAccent)
                }
                onBlur={(e) => (e.target.style.borderColor = theme.midground1)}
              />
            </div>
            <textarea
              placeholder="Section Description"
              value={sectionDescription}
              onChange={(e) => setSectionDescription(e.target.value)}
              style={{
                width: "97%",
                padding: "12px 16px",
                backgroundColor: theme.background1,
                borderRadius: "8px",
                border: `1px solid ${theme.midground1}`,
                boxShadow: `0 1px 4px rgba(0, 0, 0, 0.1)`,
                fontSize: "16px",
                minHeight: "120px",
                resize: "vertical",
                color: theme.foreground,
                fontFamily: "Hiyllo, sans-serif",
                outline: "none",
                transition: "border-color 0.2s ease",
              }}
              onFocus={(e) =>
                (e.target.style.borderColor = theme.colorSubtleAccent)
              }
              onBlur={(e) => (e.target.style.borderColor = theme.midground1)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "24px",
            }}
          >
            <Button
              onClick={() => setNewSectionCard(false)}
              style={{
                backgroundColor: theme.background2,
                color: theme.foreground,
                padding: "10px 16px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
              }}
              onMouseEnter={(e: any) =>
                (e.currentTarget.style.backgroundColor = theme.midground1)
              }
              onMouseLeave={(e: any) =>
                (e.currentTarget.style.backgroundColor = theme.background2)
              }
            >
              <FontAwesomeIcon icon={faTimes} /> Cancel
            </Button>
            <Button
              onClick={handleCreateSection}
              style={{
                backgroundColor: theme.colorSubtleAccent,
                color: theme.foreground,
                padding: "10px 16px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
              }}
              onMouseEnter={(e: any) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e: any) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <FontAwesomeIcon icon={faSave} /> Create
            </Button>
          </div>
        </NewSectionCard>
      )}
      {getAllSectionsQuery.isLoading || getRootSectionQuery.isLoading ? (
        <motion.div
          layout="position"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
          style={{ color: theme.foreground }}
        >
          <LoadingSpinner />
        </motion.div>
      ) : filteredSections.length === 0 &&
        !showNewSectionCard &&
        (!articles || articles.length === 0) ? (
        <motion.div
          layout="position"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
        >
          <NoContent plug={"No sections found."} />
        </motion.div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-Sections">
            {(provided) => (
              <div
                style={{ marginTop: 0, color: theme.foreground }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {filteredSections.map((section, index) => (
                  <Draggable
                    key={section.uuid}
                    draggableId={section.uuid}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <motion.div
                          layout="position"
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.8 }}
                          transition={{ duration: 0.3 }}
                          style={
                            {
                              backgroundColor: theme.background3,
                              borderRadius: "8px",
                              padding: "20px",
                              transition: "all 0.3s ease",
                              marginBottom: "16px",
                              cursor: "pointer",
                            } as CSSProperties
                          }
                          onClick={() => handleSectionClick(section)}
                        >
                          {editingSection &&
                          editingSection.uuid === section.uuid ? (
                            <>
                              <h3
                                style={{
                                  color: theme.foreground,
                                  marginBottom: "20px",
                                  fontSize: "22px",
                                }}
                              >
                                Edit your section
                              </h3>
                              <div
                                style={{
                                  display: "flex",
                                  gap: 15,
                                  zIndex: 100,
                                }}
                              >
                                {editingSection.icon && (
                                  <IconDisplay
                                    iconName={editingSection.icon}
                                    size="lg"
                                    color={theme.foreground}
                                  />
                                )}
                                <input
                                  type="text"
                                  value={editingSection.icon as string}
                                  onChange={(e) => {
                                    setEditingSection({
                                      ...editingSection,
                                      icon: e.target.value,
                                    });
                                  }}
                                  style={{
                                    width: "10%",
                                    padding: "12px 16px",
                                    backgroundColor: theme.background1,
                                    borderRadius: "8px",
                                    border: `1px solid ${theme.midground1}`,
                                    boxShadow: `0 1px 4px rgba(0, 0, 0, 0.1)`,
                                    fontSize: "16px",
                                    color: theme.foreground,
                                    fontFamily: "Hiyllo, sans-serif",
                                    outline: "none",
                                    transition: "border-color 0.2s ease",
                                  }}
                                />
                                <input
                                  type="text"
                                  value={editingSection.title}
                                  onChange={(e) =>
                                    setEditingSection({
                                      ...editingSection,
                                      title: e.target.value,
                                    })
                                  }
                                  style={{
                                    width: "97%",
                                    padding: "12px 16px",
                                    backgroundColor: theme.background1,
                                    borderRadius: "8px",
                                    border: `1px solid ${theme.midground1}`,
                                    boxShadow: `0 1px 4px rgba(0, 0, 0, 0.1)`,
                                    fontSize: "16px",
                                    color: theme.foreground,
                                    fontFamily: "Hiyllo, sans-serif",
                                    outline: "none",
                                    transition: "border-color 0.2s ease",
                                  }}
                                />
                              </div>
                              <textarea
                                value={editingSection.description}
                                placeholder="A description should go here..."
                                onChange={(e) =>
                                  setEditingSection({
                                    ...editingSection,
                                    description: e.target.value,
                                  })
                                }
                                style={{
                                  width: "98%",
                                  padding: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "4px",
                                  backgroundColor: theme.background1,
                                  border: `1px solid ${theme.midground1}`,
                                  fontSize: "16px",
                                  minHeight: "100px",
                                  resize: "vertical",
                                  color: theme.foreground,
                                  fontFamily: "Hiyllo, sans-serif",
                                  outline: "none",
                                  marginTop: 15,
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  gap: "12px",
                                  marginTop: "24px",
                                }}
                              >
                                <Button
                                  onClick={(e: {
                                    stopPropagation: () => void;
                                  }) => {
                                    e.stopPropagation();
                                    setEditingSection(null);
                                  }}
                                  style={{
                                    backgroundColor: theme.background2,
                                    color: theme.foreground,
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimes} /> Cancel
                                </Button>
                                <Button
                                  onClick={(e: {
                                    stopPropagation: () => void;
                                  }) => {
                                    e.stopPropagation();
                                    handleUpdateSection();
                                  }}
                                  style={{
                                    backgroundColor: theme.colorSubtleAccent,
                                    color: theme.foreground,
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSave} /> Save
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "20px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faGripVertical}
                                  style={{ fontSize: 15 }}
                                />
                              </div>
                              <div style={{ width: "100%" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: 5,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      size={"2xs"}
                                      style={{
                                        color: section.published
                                          ? "#5B9279"
                                          : "#FF6978",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <h2
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: theme.foreground,
                                      }}
                                    >
                                      {section.title}
                                    </h2>

                                    {section.icon && (
                                      <IconDisplay
                                        iconName={section.icon}
                                        size="lg"
                                        color={theme.foreground}
                                      />
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    {getChildSectionCount(section.uuid) > 0 && (
                                      <div>
                                        <FontAwesomeIcon
                                          icon={faFolder}
                                          style={{
                                            marginRight: "5px",
                                            color: theme.foreground,
                                          }}
                                        />
                                        <span
                                          style={{ color: theme.foreground }}
                                        >
                                          {getChildSectionCount(section.uuid)}
                                        </span>
                                      </div>
                                    )}
                                    {Array.isArray(section.articles) &&
                                      section.articles.length > 0 && (
                                        <div>
                                          <FontAwesomeIcon
                                            icon={faMemoPad}
                                            style={{
                                              marginRight: "5px",
                                              color: theme.foreground,
                                            }}
                                          />
                                          <span
                                            style={{ color: theme.foreground }}
                                          >
                                            {section.articles.length}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    color: theme.foreground,
                                    opacity: 0.5,
                                    marginBottom: "15px",
                                    marginTop: 5,
                                  }}
                                >
                                  {section.description}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "15px",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: 5 }}>
                                    <Button
                                      onClick={(e: {
                                        stopPropagation: () => void;
                                      }) => {
                                        e.stopPropagation();
                                        setEditingSection(section);
                                      }}
                                      style={{
                                        backgroundColor: theme.background2,
                                        color: theme.foreground,
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEdit} /> Edit
                                    </Button>
                                    <Button
                                      onClick={(e: {
                                        stopPropagation: () => void;
                                      }) => {
                                        e.stopPropagation();
                                        handlePublish(section);
                                      }}
                                      style={{
                                        backgroundColor: theme.background2,
                                        color: theme.foreground,
                                      }}
                                    >
                                      {section.published
                                        ? "Unpublish"
                                        : "Publish"}
                                    </Button>
                                  </div>
                                  <Button
                                    onClick={(e: {
                                      stopPropagation: () => void;
                                    }) => {
                                      e.stopPropagation();
                                      handleDeleteSection(section.uuid);
                                    }}
                                    style={{
                                      backgroundColor: theme.colorWarning,
                                      color: theme.foreground,
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </motion.div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default SectionList;
