import React, { createContext, useContext, useState, ReactNode } from "react";

type HeaderContentContextType = {
  headerContent: ReactNode;
  setHeaderContent: (content: ReactNode) => void;
};

const HeaderContentContext = createContext<
  HeaderContentContextType | undefined
>(undefined);

export const HeaderContentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [headerContent, setHeaderContent] = useState<ReactNode>(null);

  return (
    <HeaderContentContext.Provider value={{ headerContent, setHeaderContent }}>
      {children}
    </HeaderContentContext.Provider>
  );
};

export const useHeaderContent = () => {
  const context = useContext(HeaderContentContext);
  if (!context) {
    throw new Error(
      "useHeaderContent must be used within a HeaderContentProvider"
    );
  }
  return context;
};
