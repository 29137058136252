/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Section } from "../../../types/knowledge-base/section";

export type ParamsType = {
  uuid: string;
};
export type ResponseType = {
  section: Section | null;
};
export const Endpoint = "section/retrieve-section";
export const Method = "GET";
export const isPublic = true;
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 5,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}