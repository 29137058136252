/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  name?: string;
  logo?: string;
  primaryColor?: string;
  secondaryColor?: string;
};
export type ResponseType = {};
export const Endpoint = "admin/settings/appearance/modify-appearance";
export const Method = "POST";
export const isPublic = false;

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"object","properties":{"name":{"type":"string"},"logo":{"type":"string"},"primaryColor":{"type":"string"},"secondaryColor":{"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}