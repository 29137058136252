import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/pro-solid-svg-icons";
import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface IconDisplayProps {
  iconName: string;
  color?: string;
  size?: SizeProp | number;
  iconStyle?: CSSProperties;
}

export const IconDisplay: React.FC<IconDisplayProps> = ({
  iconName,
  color,
  size,
  iconStyle,
}) => {
  // Retrieve the icon definition from the Icons object
  const iconDefinition = (Icons as Record<string, unknown>)[iconName] as
    | IconDefinition
    | undefined;

  if (!iconDefinition) {
    return null;
  }

  return (
    <FontAwesomeIcon
      icon={iconDefinition}
      color={color}
      size={typeof size === "number" ? undefined : size}
      fontSize={typeof size === "number" ? size : undefined}
      style={iconStyle}
    />
  );
};
