import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import { Input } from "@hiyllo/ux/input";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEnvelope,
  faPlus,
  faQuestion,
} from "@fortawesome/pro-solid-svg-icons";
import NoContent from "../../../../../../components/no-content";
import { useAlert } from "../../../../../../providers/alert-provider";
import * as InviteAgentBP from "../../../../../../blueprints/onboarding/invite-agent";
import * as FetchAgentsInvitesBP from "../../../../../../blueprints/onboarding/fetch-agents-invites";
import * as RevokeAgentsInviteBP from "../../../../../../blueprints/onboarding/revoke-agent-invite";
import * as CheckAgentEmailBP from "../../../../../../blueprints/onboarding/check-agent-email";
import {
  authExtension,
  client,
} from "../../../../../../singletons/moopsy-client";
import { AgentInviteType } from "../../../../../../types/agent-invites";

const Container = styled("div", (props) => ({
  color: props.$theme.foreground,
  display: "flex",
  gap: "15px",
}));

const InviteFormSection = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "15px",
});

const InviteListSection = styled("div", {
  flex: 2,
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const InviteCard = styled(motion.li, (props) => ({
  padding: "12px 0px",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: props.$theme.background1,
  color: props.$theme.foreground,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Header = styled("div", (props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginBottom: "24px",
}));

const AgentInput = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "20px",
  backgroundColor: props.$theme.background3,
  padding: "8px 16px",
  marginBottom: "8px",
}));

const StyledButton = styled("button", (props) => ({
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  transition: "background-color 0.3s ease",
}));

const AddAgentsComponent = () => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const showDialog = useShowDialog();
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const authState = authExtension.useAuthState();
  const [invites, setInvites] = React.useState<AgentInviteType[]>([]);

  const [emailExists, setEmailExists] = React.useState<boolean | null>(null);
  const [existingUsername, setExistingUsername] = React.useState("");

  const inviteAgentMutation =
    client.useMutation<InviteAgentBP.Plug>(InviteAgentBP);
  const revokeAgentInviteMutation =
    client.useMutation<RevokeAgentsInviteBP.Plug>(RevokeAgentsInviteBP);
  const checkAgentEmailMutation =
    client.useMutation<CheckAgentEmailBP.Plug>(CheckAgentEmailBP);
  const agentInvitesQuery = client.useQuery<FetchAgentsInvitesBP.Plug>(
    FetchAgentsInvitesBP,
    {}
  );

  React.useEffect(() => {
    if (agentInvitesQuery.data && agentInvitesQuery.data.invites?.length > 0) {
      console.log(JSON.stringify(agentInvitesQuery.data.invites, null, 2));
      setInvites(agentInvitesQuery.data.invites);
    }
  }, [agentInvitesQuery.data]);

  const handleInvite = React.useCallback(() => {
    if (email === "") {
      pushAlert("Please enter an email", "error");
      return;
    }

    if (emailExists === false && username === "") {
      pushAlert("Please enter a username", "error");
      return;
    }

    try {
      inviteAgentMutation
        .call({ invitorId: authState?.uuid || "", email, username })
        .then((r) => {
          pushAlert("Agent invited", "success");
          agentInvitesQuery.refresh();
          setEmail("");
          setUsername("");
          setEmailExists(null);
          setExistingUsername("");
        })
        .catch((e) => {
          pushAlert(e.error, "error");
          setEmail("");
          setUsername("");
          setEmailExists(null);
          setExistingUsername("");
        });
    } catch {
      pushAlert("Failed to invite agent", "error");
    }
  }, [
    agentInvitesQuery,
    authState?.uuid,
    email,
    emailExists,
    inviteAgentMutation,
    pushAlert,
    username,
  ]);

  const handleRevokeInvite = React.useCallback(
    (inviteId: string) => {
      showDialog({
        icon: faQuestion,
        title: "Revoke Invite",
        message: "Are you sure you want to revoke this invite?",
        onConfirm: () => {
          try {
            revokeAgentInviteMutation.call({ inviteId }).then((r) => {
              pushAlert("Invite revoked", "success");
              agentInvitesQuery.refresh();
            });
          } catch {
            pushAlert("Failed to revoke invite", "error");
          }
        },
      });
    },
    [showDialog, revokeAgentInviteMutation, pushAlert, agentInvitesQuery]
  );

  const checkEmail = React.useCallback(async () => {
    try {
      const response = await checkAgentEmailMutation.call({ email });
      if (response.exists && response.username) {
        setEmailExists(true);
        setExistingUsername(response.username);
      } else {
        setEmailExists(false);
      }
    } catch (error: any) {
      pushAlert(error.error, "error");
    }
  }, [checkAgentEmailMutation, email, pushAlert]);

  return (
    <>
      <Container>
        <InviteFormSection>
          <motion.div>
            <Header>
              <AgentInput>
                <FontAwesomeIcon icon={faEnvelope} />
                <Input
                  type="text"
                  placeholder="Agent's email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  containerStyle={{
                    border: "none",
                    background: "transparent",
                    marginLeft: "8px",
                    fontSize: "16px",
                    width: "100%",
                    outline: "none",
                    color: theme.foreground,
                  }}
                />
              </AgentInput>
              {emailExists === null && (
                <StyledButton
                  onClick={checkEmail}
                  style={{
                    backgroundColor: theme.colorSubtleAccent,
                    color: theme.foreground,
                    width: "50%",
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Next
                </StyledButton>
              )}
              {emailExists === true && (
                <>
                  <p style={{ fontSize: 15 }}>
                    Username already exists, we will use that.
                  </p>
                  <AgentInput>
                    <FontAwesomeIcon icon={faAt} />
                    <Input
                      type="text"
                      placeholder="Agent's username"
                      value={existingUsername}
                      containerStyle={{
                        border: "none",
                        background: "transparent",
                        marginLeft: "8px",
                        fontSize: "16px",
                        width: "100%",
                        outline: "none",
                        color: theme.foreground,
                      }}
                    />
                  </AgentInput>
                  <StyledButton
                    onClick={handleInvite}
                    style={{
                      backgroundColor: theme.colorSubtleAccent,
                      color: theme.foreground,
                      width: "50%",
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Invite Agent
                  </StyledButton>
                </>
              )}
              {emailExists === false && (
                <>
                  <AgentInput>
                    <FontAwesomeIcon icon={faAt} />
                    <Input
                      type="text"
                      placeholder="Agent's username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      containerStyle={{
                        border: "none",
                        background: "transparent",
                        marginLeft: "8px",
                        fontSize: "16px",
                        width: "100%",
                        outline: "none",
                        color: theme.foreground,
                      }}
                    />
                  </AgentInput>
                  <StyledButton
                    onClick={handleInvite}
                    style={{
                      backgroundColor: theme.colorSubtleAccent,
                      color: theme.foreground,
                      width: "50%",
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Invite Agent
                  </StyledButton>
                </>
              )}
            </Header>
          </motion.div>
        </InviteFormSection>

        <InviteListSection>
          {invites.length > 0 && (
            <motion.h2
              style={{
                marginBottom: "15px",
                color: theme.foreground,
              }}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
            >
              Current Invites
            </motion.h2>
          )}
          {invites.length > 0 ? (
            <motion.ul
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
              }}
              style={{
                listStyle: "none",
                padding: 0,
                width: "100%",
              }}
            >
              {invites.map((invite, index) => (
                <InviteCard
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{invite.email}</span>
                    <span
                      style={{
                        color: invite.claimed
                          ? theme.colorSubtleAccent
                          : theme.colorWarning,
                      }}
                    >
                      {invite.claimed ? "Claimed" : "Not Claimed Yet"}
                    </span>
                  </div>
                  <StyledButton
                    style={{
                      backgroundColor: theme.midground1,
                      color: theme.foreground,
                    }}
                    onClick={() => handleRevokeInvite(invite.inviteId)}
                  >
                    Revoke Invite
                  </StyledButton>
                </InviteCard>
              ))}
            </motion.ul>
          ) : (
            <NoContent
              plug={
                "Nobody has been invited yet, go ahead and invite an agent."
              }
            />
          )}
        </InviteListSection>
      </Container>
    </>
  );
};

export const AddAgents = React.memo(AddAgentsComponent);
